<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-chip
        class="ma-1"
        color="blue lighten-5"
        text-color="black"
        v-on="on"
      >
        <v-avatar left
          class="blue darken-4"
          style="color: white !important"
        >
          <v-icon small>{{ btnIcon }}</v-icon>
        </v-avatar>
        <span>{{ btnText }}</span>
      </v-chip>
    </template>
    <v-card class="mx-auto" :loading="loading">
      <v-card-title><span v-if="isNew">Criar obra</span><span v-if="!isNew">Alterar obra</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
              <v-text-field label="Nome" :rules="nameRules" v-model="work.Name"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="!isNew">
          <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
            <v-text-field label="Coordenadas" :rules="coordinatesRules" v-model="work.Coords"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!isNew">
          <v-col cols="12" sm="12" md="12" class="nm-NoPaddingTopBot">
            <v-menu v-model="menuStartDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="250px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="startDate"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" @input="menuStartDate = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!--<v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-textarea
              rows="5"
              counter
              name="input-7-4"
              label="Notes"
              v-model="work.Notes"
            ></v-textarea>
          </v-col>
        </v-row>-->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text v-if="isNew" @click="create()">Criar</v-btn>
        <v-btn color="info" text v-if="!isNew" @click="save()">Alterar</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    action: String,
    details: {}
  },
  data () {
    return {
      loading: false,
      isNew: true,
      work: {},
      notes: {},
      error: '',
      dialog: false,
      places: [],
      valid: false,
      nameRules: [
        v => !!v || 'Nome é obrigatório'
      ],
      coordinatesRules: [
        v => /(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)/.test(v) || 'As coordenadas são inválidas',
      ],
      menuStartDate: false,
      startDate: new Date().toISOString().substr(0, 10),
    }
  },
  computed: {
    btnIcon: function () {
      if (this.action === 'update') { return 'mdi-pencil' } else { return 'mdi-plus' }
    },
    btnText: function () {
      if (this.action === 'update') { return 'Alterar' } else { return 'Criar' }
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        if(this.action === 'update') {
          this.isNew = false
          this.work = this.details

          this.work.Coords = this.work.Latitude + "," + this.work.Longitude

          this.startDate = new Date(this.work.DateTime).toISOString().substr(0, 10)
        }
      }
    },
  },
  methods: {
    create () {
      let curDate = new Date().toISOString().substr(0, 19)

      this.work.CreationDate = curDate + "Z"
      this.work.LastModifyDate = curDate + "Z"

      httpClient
        .post('/mix/api/v1/works', this.work)
        //.post('http://localhost:13007/api/v1/works', this.work)
        .then(() => {
          this.dialog = false
          this.work = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Work successfully created'})
          bus.$emit('refreshWorks', null)
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating work'})
        })
    },
    save () {
      this.work.DateTime = this.startDate + "T00:00:00Z"
      this.work.LastModifyDate = new Date().toISOString()
      this.loading = true
      this.saveWork()
    },
    saveWork () {
      this.loading = true
      httpClient
        .put('/mix/api/v1/works/' + this.work.ID, this.work)
        //.put('http://localhost:13007/api/v1/works/' + this.work.ID, this.work)
        .then(() => {
          bus.$emit('snackBarMsg', {error: false, msg: 'Obra atualizada com sucesso'})
          // Refresh work view
          bus.$emit('refreshWorkDetails')
          this.loading = false
          this.dialog = false
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao atualizar obra'})
          this.loading = false
        })
    }
  }
}
</script>