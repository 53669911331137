<template>
  <v-layout style="flex-wrap: wrap">
    <v-flex v-for="i in items" :key="i.title" xs12 sm6 md4>
      <v-card class="mx-2 my-2" elevation="1" outlined>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>{{ i.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{ i.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          {{ i.description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="info" link :to="i.link">
            Manage
          </v-btn>
          <!--<v-btn icon>
            <v-icon>mdi-heart</v-icon>
          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      items: [
        /*{ title: 'Staff', description: 'Manage your staff',
          link: '/staff', icon: 'mdi-briefcase-account' },*/
        { title: 'Packages', description: 'Manage your packages',
          link: '/packages', icon: 'mdi-package-variant' },
        { title: 'Services', description: 'Manage your services',
          link: '/services', icon: 'mdi-room-service-outline' },
        { title: 'Places', description: 'Manage your places',
          link: '/place', icon: 'mdi-map-marker' },
        { title: 'Transports', description: 'Manage your transports',
          link: '/transport', icon: 'mdi-train-car' },
        { title: 'Equipments', description: 'Manage your equipments',
          link: '/item', icon: 'mdi-nas' },
        { title: 'Event Types', description: 'Manage your event types',
          link: '/lessontypes', icon: 'mdi-format-list-bulleted-type' },
        { title: 'Suppliers', description: 'Manage your suppliers',
          link: '/supplier', icon: 'mdi-factory' },
        { title: 'Rooms', description: 'Manage your rooms',
          link: '/rooms', icon: 'mdi-home-city-outline'},
      ],
    };
  },
};
</script>