<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Select transport for {{ studentName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="studentTransport.IDTransport"
                  :items="transportList"
                  item-text="Name"
                  item-value="ID"
                  item-disabled="Full"
                  label="Transport"
                  required
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addOrRemove()">Update</v-btn>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    transportList: [],
    studentTransport: {},
    studentName: "",
  }),
  created() {
    bus.$on('openLessonStudentTransportDialog', (data) => {
      this.studentName = data.studentName
      this.studentTransport.IDLesson = data.idLesson
      this.studentTransport.IDStudent = data.idStudent
      this.studentTransport.IDTransport = data.idTransport
      this.dialog = true
      this.getTransports()
    })
  },
  beforeDestroy () {
    bus.$off('openLessonStudentTransportDialog', null)
  },
  methods: {
    getTransports: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/transports')
        //.get('http://localhost:13007/api/v1/transports')
        .then((resp) => {
          this.loading = false
          if(resp) {    
            this.transportList = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Error getting packages'})
        })
    },
    addOrRemove: function () {
      this.loading = true
      if(this.studentTransport.IDTransport === undefined) {
        httpClient
          .delete('/event/api/v1/lessontransport/' + this.studentTransport.IDLesson + '/' + this.studentTransport.IDStudent)
          //.delete('http://localhost:13007/api/v1/lessontransport/' + this.studentTransport.IDLesson + '/' + this.studentTransport.IDStudent)
          .then(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: false, msg: 'Transport removed'})
            bus.$emit('refreshLessonStudentList')
            bus.$emit('refreshLessonTransports')
            this.studentTransport = {}
            this.dialog = false
          })
          .catch(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: false, msg: 'Error creating student transport'})
          })
      } else {
        httpClient
          .post('/event/api/v1/lessontransport', this.studentTransport)
          //.post('http://localhost:13007/api/v1/lessontransport', this.studentTransport)
          .then(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: false, msg: 'Transport added to student'})
            bus.$emit('refreshLessonStudentList')
            bus.$emit('refreshLessonTransports')
            this.studentTransport = {}
            this.dialog = false
          })
          .catch(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: false, msg: 'Error creating student transport'})
          })
      }
    },
  },
}
</script>