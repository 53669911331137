<template>
  <div>
    <div>
      <highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>
    </div>
  </div>  
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import {Chart} from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart
  },
  data () {
    return {
      yearList: this.$store.state.yearList,
      monthList: this.$store.state.monthList,
      yearSelected: new Date().getFullYear(),
      monthSelected: 0,
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          //verticalAlign: 'middle',
          text: 'Country'
        },
        legend: {
          labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
        },
        series: [{
          innerSize: '50%',
          name: 'Lessons',
          keys: ['name', 'y'],
          data: [],
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
        }]
      },
      updateArgs: [true, true, { duration: 1000 }]
    }
  },
  created() {
    bus.$on("ChartsMonth", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
    })
    bus.$on("ChartsYear", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
    })
  },
  watch: {
    monthSelected() { this.get() },
    yearSelected() { this.get() }
  },
  mounted () {
    this.get()
    document.getElementsByClassName('highcharts-credits')[0].remove()
  },
  methods: {
    get() {
      httpClient
        .get('mix/api/v1/reports/countries/' + this.yearSelected + '/' + this.monthSelected)
        //.get('http://localhost:13007/api/v1/reports/countries/' + this.yearSelected + '/' + this.monthSelected)
        .then((resp) => {
          //this.chartOptions.title.text = "Countries (" + this.monthSelected + "/" + this.yearSelected + ") "
          this.chartOptions.series[0].data = []

          if (resp.data !== null) {
            resp.data.forEach(e => {
              if (e.countryName !== undefined) {
                this.chartOptions.series[0].data.push([e.countryName, e.total])
              }
            })
          }
        })
        .catch(() => { 
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting countries information'})
        })
    }
  }
}
</script>