<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            {{ this.customer.Name }} <StudentDialog :sAction="'update'" :sDetails="customer" /> <StudentNotes :sDetails="customer" />
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="4">
                <span class="font-weight-medium">Email:</span> {{ customer.Email }}
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <span class="font-weight-medium">Phone:</span> {{ customer.Phone }}
              </v-col>
              <!--<v-col cols="12" sm="6" md="4" lg="4">
                <span class="font-weight-medium">Document ID:</span> {{ customer.DocumentID }}
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <span class="font-weight-medium">Birth Date:</span> {{ customer.BirthDate }}
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" v-if="edit">
                <v-menu
                  v-model="datePickerBirthdate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="customer.BirthDate"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar-account"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="customer.BirthDate" @input="datePickerBirthdate = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <span class="font-weight-medium">Address:</span> {{ customer.Address }}
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <span class="font-weight-medium">Country:</span> {{ customer.Country }}
              </v-col>-->
              <v-col cols="12" sm="12" md="12" lg="12">
                <span class="font-weight-medium">Notes:</span> <pre>{{ customerNotes }}</pre>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-chip color="info" outlined class="ma-1" v-if="customer.Board"><div>{{ customer.Board }}</div></v-chip>
            <v-chip color="info" outlined class="ma-1" v-if="customer.Wetsuit"><div>{{ customer.Wetsuit }}</div></v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col xs="12" sm="6" md="6" lg="6">
        <StudentPackageChipList :customerID="this.customer.ID" v-if="this.customer.ID" :key="this.customer.ID" />
      </v-col>
      <v-col xs="12" sm="6" md="6" lg="6">
        <StudentServiceChipList :customerID="this.customer.ID" v-if="this.customer.ID" :key="this.customer.ID" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <StudentLesson :customerID="this.customer.ID" v-if="this.customer.ID" :key="this.customer.ID" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
import StudentDialog from './StudentDialog.vue'
import StudentNotes from './StudentNotes.vue'
import StudentLesson from './StudentLesson.vue'
import StudentPackageChipList from './StudentPackageChipList.vue'
import StudentServiceChipList from './StudentServiceChipList.vue'

export default {
  data () {
    return {
      datePickerBirthdate: false,
      dataBirthDate: '',
      customer: {},
      customerNotes: "",
      customerPackages: {},
      edit: false,
      date: new Date().toISOString().substr(0, 10),
      loading: false,
    }
  },
  mounted () {
    this.dataBirthDate = moment.parseZone(this.customer.BirthDate).format('YYYY-MM-DD')
  },
  created () {
    this.get(this.$route.params.id)
    this.getNotes(this.$route.params.id)
    bus.$on('refreshStudentView', () => {
      this.get(this.$route.params.id)
      this.getNotes(this.$route.params.id)
    })
  },
  beforeDestroy () {
    bus.$off('refreshStudentView', null)
  },
  methods: {
    get (studentID) {
      this.loading = true
      httpClient
        .get('/customer/api/v1/student/' + studentID)
        //.get('http://localhost:13001/api/v1/student/' + studentID)
        .then((r) => {
          this.loading = false
          this.customer = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating customer'})
        })
    },
    getNotes(studentID) {
      httpClient
        .get('/customer/api/v1/student/' + studentID + '/notes')
        //.get('http://localhost:13001/api/v1/student/' + studentID + '/notes')
        .then((response) => {
          this.customerNotes = response.data.Notes
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student notes'})
        })
    },
  },
  components: {
    StudentDialog,
    StudentNotes,
    StudentLesson,
    StudentPackageChipList,
    StudentServiceChipList
  }
}
</script>