<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-briefcase-account</v-icon> <span>{{ this.s.Name }}</span></div>
            <!-- TODO <span contenteditable="true">{{ this.s.Name }}</span> -->
            <v-spacer></v-spacer>

            <StaffDialog :staffAction="'update'" :staffDetails="s" />
            <v-chip @click="openResetPassDialog()" v-if="isAdmin()"
              class="ma-0 mx-1" color="green lighten-5" text-color="black"
              style="margin-top: 40px; ">
              <v-avatar left
                class="green darken-4" style="color: white !important">
                <v-icon small>mdi-lock</v-icon>
              </v-avatar>
              New Password
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <span class="font-weight-medium">Profile:</span> {{ s.Role }}
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <span class="font-weight-medium">Email:</span> {{ s.Email }}
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <span class="font-weight-medium">Phone:</span> {{ s.Phone }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="!isWorksApp()">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-calendar-account</v-icon> <span>Lessons</span></div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="columns"
              :items="lessons"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page="5"
              :server-items-length="totalRows"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: rowsPerPageItems,
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-arrow-left',
                nextIcon: 'mdi-arrow-right'
              }"
            >
              <template v-slot:item.DateTime="{ item }">
                {{ formatDate(item.DateTime) }}
              </template>
              <template v-slot:item.options="{ item }">
                <router-link v-bind:to="'/lesson/' + item.id">
                  <v-chip class="m-0 s-chip" color="blue lighten-5">
                    <v-avatar class="blue darken-4" style="color: white !important">
                      <v-icon small>mdi-forward</v-icon>
                    </v-avatar>
                  </v-chip>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>

      <!--<v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="isWorksApp()">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-gas-station-outline</v-icon> <span>Histórico de Obras</span></div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="columns"
              :items="works"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page="10"
              :server-items-length="totalRows"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: rowsPerPageItems,
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-arrow-left',
                nextIcon: 'mdi-arrow-right'
              }"
            >
              <template v-slot:item.DateTime="{ item }">
                {{ formatDate(item.DateTime) }}
              </template>
              <template v-slot:item.options="{ item }">
                <router-link v-bind:to="'/work/' + item.ID">
                  <v-chip class="m-0 s-chip" color="blue lighten-5">
                    <v-avatar class="blue darken-4" style="color: white !important">
                      <v-icon small>mdi-forward</v-icon>
                    </v-avatar>
                  </v-chip>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>-->
    </v-row>

    <!--<v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <StaffEventTable :staffID="this.s.ID" v-if="this.s.ID" :key="this.s.ID" />
      </v-col>
    </v-row>-->

    <!--<v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <StaffWorkTable :staffID="this.s.ID" v-if="this.s.ID" :key="this.s.ID" />
      </v-col>
    </v-row>-->
    <StaffPasswordDialog />
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import StaffDialog from './StaffDialog.vue'
import StaffPasswordDialog from './StaffPasswordDialog'

export default {
  data () {
    return {
      loading: false,
      s: {},
      lessons: [],
      works: [],
      columns: [
        { text: 'Start time', align: 'left', sortable: true, value: 'DateTime' },
        { text: 'Duration', align: 'left', sortable: true, value: 'Minutes' },
        { text: 'Place', align: 'left', sortable: true, value: 'Place' },
        { text: 'Type', align: 'left', sortable: true, value: 'Type' },
        { text: 'Payment', align: 'left', sortable: true, value: 'Payment' },
        { text: 'Options', sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25],
      totalRows: 0,
      options: {},
      sortBy: 'datetime',
      sortDesc: true,
    }
  },
  created () {
    bus.$on('refreshStaffView', () => { this.get() })
    this.get()
    if(this.isWorksApp()) {
      this.getWorks()
    }
  },
  beforeDestroy () {
    bus.$off('refreshStaffView', null)
  },
  watch: {
    options: {
      handler () {
        this.getLessons()
      },
      deep: true,
    },
  },
  methods: {
    isAdmin() {
      return this.$store.state.profile === "Administrator"
    },
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    isWorksApp() {
      return (/*this.$store.state.database === 'demosmartsurf' || */this.$store.state.database === 'manuelpedrosousa')
    },
    openResetPassDialog () {
      bus.$emit('changeStaffPassword', {staff: this.s})
    },
    get() {
      var id = this.$route.params.id
      this.loading = true
      httpClient
        .get('/mix/api/v1/staff/' + id)
        //.get('http://localhost:13007/api/v1/staff/' + id)
        .then((r) => {
          this.loading = false
          this.s = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter trabalhador'})
        })
    },
    getWorks () {
      var id = this.$route.params.id
      this.loading = true
      httpClient
        .get('/mix/api/v1/works/staff/' + id)
        //.get('http://localhost:13007/api/v1/works/staff/' + id)
        .then((r) => {
          this.loading = false
          this.works = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter trabalhador'})
        })
    },
    getLessons () {
      var id = this.$route.params.id
      this.loading = true
      httpClient
        .get('/event/api/v1/lessons/staff/' + id + '?' +
        //.get('http://localhost:13007/api/v1/lessons/staff/' + id + '?' +
          'pageNumber='+this.options.page+'&pageSize='+this.options.itemsPerPage+
          '&sortBy='+this.options.sortBy+'&sortDesc='+this.options.sortDesc)
        .then((r) => {
          this.loading = false
          this.lessons = r.data.events
          this.totalRows = r.data.totalRows
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro getting lessons'})
        })
    }
  },
  components: {
    StaffDialog,
    StaffPasswordDialog
  }
}
</script>