var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{attrs:{"loading":_vm.loading,"elevation":"1"}},[_c('v-card-title',[_c('div',{staticClass:".display-3"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "),_c('span',{attrs:{"contenteditable":"true"}},[_vm._v(_vm._s(this.place.Name))])],1),_c('v-spacer'),_c('PlaceDialog',{attrs:{"placeAction":'update',"placeDetails":_vm.place}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('gmap-map',{staticStyle:{"width":"100%","height":"150px"},attrs:{"options":{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                },"center":_vm.center,"zoom":_vm.zoom,"map-type-id":_vm.mayType}},_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"clickable":true},on:{"click":function($event){return _vm.openPanel(m)}}})}),1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Latitude:")]),_vm._v(" "+_vm._s(_vm.place.Latitude)+" ")]),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"6"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Longitude:")]),_vm._v(" "+_vm._s(_vm.place.Longitude)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Country:")]),_vm._v(" "+_vm._s(_vm.place.Country)+" ")])],1)],1),_c('v-divider')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }