<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>{{ formatDate(lesson.DateTime) }}</v-card-title>
      <v-card-text>
        Are you sure you want to remove the student from this lesson?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="deleteStudentLesson()">
          Confirm Delete
        </v-btn>
        <v-btn color="primary" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      lesson: {},
      customerID: 0,
    }
  },
  created () {
    bus.$on('openStudentLessonDeleteConfirm', (data) => {
      this.dialog = true
      this.lesson = data.lesson
      this.customerID = data.customerID
      console.log(this.lesson, this.customerID)
    })
  },
  beforeDestroy () {
    bus.$off('openStudentLessonDeleteConfirm', null)
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    deleteStudentLesson () {
      this.loading = true
      httpClient
        .delete('/event/api/v1/lessonstudent/' + this.lesson.ID + '/' + this.customerID)
        //.delete('http://localhost:13007/api/v1/lessonstudent/' + this.lesson.ID + '/' + this.customerID)
        .then(() => {
          this.loading = false
          bus.$emit('refreshStudentLessons')
          bus.$emit('refreshStudentPackages')
          bus.$emit('refreshStudentServices')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error removing student from lesson'})
        })
    },
  }
}
</script>
