<template>
  <div>
    <v-card
      :loading="loading"
      class="mx-auto elevation-1"
      max-width="80%">

      <v-img 
        class="white--text align-end"
        src="@/assets/praia_ondas.jpg"
        max-height="150px"
        style="object-fit: contain; object-position: bottom;">
        <v-card-title
          class="justify-end">
          <img style="height: 50px;" src="@/assets/logo.png" />
        </v-card-title>
      </v-img>

      <v-card-title>Account Activation</v-card-title>

      <v-card-text>
          <v-alert
            icon="mdi-shield-lock-outline"
            prominent
            text
            :type="msgType"
            class="blinking-element"
          >
            <b>nmSmart</b> - {{ result }}
          </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link v-bind:to="'/true'">
          <v-btn text>Login</v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'

export default {
  data () {
    return {
      loading: false,
      msgType: "success",
      msg: {},
      result: "",
    }
  },
  created() {
    this.msg.activation_code = this.$route.params.code
    this.sendActivationToken()
  },
  methods: {
    sendActivationToken() {
      this.loading = true
      httpClient
        .post('/oauth/api/v1/register/activate', this.msg)
        //.post('http://localhost:13098/api/v1/register/activate', this.msg)
        .then(r => {
          this.loading = false
          if (r.data.error) {
            this.msgType = "error"
          }
          this.registered = false
          this.result = r.data.message
        })
        .catch(e => {
          this.loading = false
          console.error(e.message)
          this.result = "Plase contact the administrator at info@smartsurf.pt"
        })
    }
  }
}
</script>