import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    yearList: [
      {"id": 2023, "name": "2023"}, {"id": 2022, "name": "2022"}, 
      {"id": 2021, "name": "2021"}, {"id": 2020, "name": "2020"},
      {"id": 2019, "name": "2019"}, {"id": 2018, "name": "2018"},
      {"id": 2017, "name": "2017"}, {"id": 2015, "name": "2016"},
      {"id": 2016, "name": "2015"},
    ],
    monthList: [
      {"id": 0, "name": "None"},
      {"id": 1, "name": "January"}, {"id": 2, "name": "February"}, {"id": 3, "name": "March"},
      {"id": 4, "name": "April"}, {"id": 5, "name": "May"}, {"id": 6, "name": "June"},
      {"id": 7, "name": "July"}, {"id": 8, "name": "August"}, {"id": 9, "name": "September"},
      {"id": 10, "name": "October"}, {"id": 11, "name": "November"}, {"id": 12, "name": "December"},
    ],
    user: {},
    userName: "",
    userEmail: "",
    token: null,
    database: null,
    profile: null,
    isAdmin: false,
    isMaster: false,
    history: [],
    calendar: {
      type: 'week',
      start: null,
      end: null,
    },
    filters: {
      student: {
        list: ''
      },
      lesson: {
        list: ''
      },
      customer: {
        list: ''
      },
      event: {
        list: ''
      },
      work: {
        list: ''
      },
    }
  },
  mutations: {
    setUserName (state, value) {
      state.userName = value
    },
    setUserEmail (state, value) {
      state.userEmail = value
    },
    setUser (state, value) {
      state.user = value
    },
    setToken (state, value) {
      state.token = value
    },
    setDatabase (state, value) {
      state.database = value
    },
    setProfile (state, value) {
      state.profile = value
    },
    setIsAdmin (state, value) {
      state.isAdmin = value
    },
    setIsMaster (state, value) {
      state.isMaster = value
    },
    add (state, value) { 
      if (state.history.length >= 6) {
        state.history.shift()
      }
      state.history.push(value)
    },
    calendarType (state, value) {
      state.calendar.type = value
    },
    calendarStart (state, value) {
      state.calendar.start = value
    },
    calendarEnd (state, value) {
      state.calendar.end = value
    },
    filtersStudentList (state, value) {
      state.filters.student.list = value
    },
    filtersLessonList (state, value) {
      state.filters.lesson.list = value
    },
    filtersWorkList (state, value) {
      state.filters.work.list = value
    },
  },
  plugins: [createPersistedState({
    paths: ['userName', 'token', 'database', 'profile', 'isAdmin', 'isMaster']
  })],
})