<template>
  <v-footer v-if="false" fixed class="font-weight-normal nmFooter" style="z-index: 4">
    <v-col class="text-right" cols="12">
      <!--<v-fade-transition> 
        <v-avatar
          color="info"
          :style="{
            animationDuration: '1.5s'
          }"
          class="v-avatar--metronome"
          size="12"
        ></v-avatar>
      </v-fade-transition>-->
      <v-btn
        v-for="icon in icons"
        :key="icon.icon"
        class="mx-0"
        color="green"
        icon
      >
        <a v-bind:href="icon.url" target="_blanc">
          <v-icon size="20px">{{ icon.icon }}</v-icon>
        </a>
      </v-btn>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      //{icon: 'mdi-twitter', url: 'https://twitter.com/NelsonnJVF/'},
      //{icon: 'mdi-linkedin-box', url: 'https://www.instagram.com/besmartsurf/'},
      {icon: 'mdi-instagram', url: 'https://www.instagram.com/besmartsurf/'},
    ],
  }),
}
</script>

<style>
@media (max-width: 600px) {
  .nmFooter {
    display: none !important;
  }
}

.v-footer div {
  padding: 3px 10px !important;
}

@keyframes metronome-example {
  from {
    transform: scale(.5);
  }

  to {
    transform: scale(1);
  }
}

.v-avatar--metronome {
  animation-name: metronome-example;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>