<template>
  <v-card :loading="loading">
    <div>
      <v-chip-group column active-class="primary--text">
        <v-spacer></v-spacer>
        <v-chip filter outlined v-for="i in logsList" :key="i.Name" @click="getLogFileContent(i.Fullname)">
          {{ i.Name }}
        </v-chip>
        <v-spacer></v-spacer>
      </v-chip-group>
    </div>
    <v-card flat>
      <v-card-title>{{ curLog }}</v-card-title>
      <v-card-text>
        <pre class="logContent">{{ log }}</pre>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      logsList: {},
      curLog: '',
      log: '',
    }
  },
  mounted () {
    this.getLogsList()
  },
  methods: {
    getLogsList: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/nmbot/logs')
        .then((resp) => {
          this.loading = false
          this.logsList = resp.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting file logs list'})
        })
    },
    getLogFileContent: function (fileName) {
      this.loading = true
      this.log = "";
      httpClient
        .get('/mix/api/v1/nmbot/logs/' + fileName)
        .then((r) => {
          this.loading = false
          this.curLog = fileName
          if(r.data) {
            this.log = r.data.reverse().join('\n')
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting file log content - ' +fileName})
        })
    },
  },
}
</script>

<style>
.logContent{
  font-size: 11px;
  max-height: 450px;
  white-space: pre-wrap;
  overflow-y: scroll;
  padding: 5px;
}
</style>