var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"blue","icon":""}},on),[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1)]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.date);_vm.dayChange()}}},[_vm._v("OK")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancel")])],1)],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.styledDate))]),_c('v-spacer'),_c('gb-flag',{attrs:{"code":"pt","size":"small"}}),_c('v-toolbar-items',{staticStyle:{"height":"unset"}},[_c('LessonDialog')],1)],1),_c('br'),_c('v-card',{attrs:{"loading":_vm.loadingDaily}},[_c('v-responsive',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('gmap-map',{staticStyle:{"width":"100%","height":"350px"},attrs:{"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
              },"center":_vm.center,"zoom":_vm.zoom,"map-type-id":_vm.mapType}},_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"clickable":true},on:{"click":function($event){return _vm.openPanel(m)}}})}),1),_c('WorkViewFullDialog')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Equipment to prepare")]),_c('v-card-text',_vm._l((_vm.groupedDataBoardsWetsuits),function(e){return _c('div',{key:'Board' + e.groupKey,staticStyle:{"margin":"0px"},attrs:{"id":'board'}},[_c('h2',{staticStyle:{"padding":"15px 0","color":"rgb(78, 113, 178)"}},[_vm._v(_vm._s(_vm.getHours(e.groupKey)))]),_c('h4',[_vm._v("Boards")]),_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((e.boardTotals),function(boardTotal,boardId){return _c('li',{key:boardId},[_c('b',[_vm._v(_vm._s(boardTotal.total))]),_vm._v(" unit(s) of "),_c('b',[_vm._v(_vm._s(boardTotal.name))])])}),0),_c('h4',[_vm._v("Wetsuits")]),_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((e.wetsuitTotals),function(wetsuitTotals,wetsuitID){return _c('li',{key:wetsuitID},[_c('b',[_vm._v(_vm._s(wetsuitTotals.total))]),_vm._v(" unit(s) of "),_c('b',[_vm._v(_vm._s(wetsuitTotals.name))])])}),0)])}),0)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }