<template>
  <div>
    <div class="fixed-rgpd-container">
      <div v-if="showBalloon" class="balloon-container" @click="toggleBalloon">
        <div class="balloon-message">Carregue aqui para ler a nossa Política de Privacidade.</div>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="90%">
      <v-card class="mx-auto elevation-1">
        <v-card-title>Política de Privacidade</v-card-title>
        <v-card-text>
          <p>Agradecemos por visitar o nosso website <b>www.smartsurf.pt</b>. 
            A sua privacidade é importante para nós, e estamos comprometidos em proteger os seus dados pessoais. 
            Esta Política de Privacidade destina-se a informá-lo sobre como recolhemos, usamos e protegemos as suas informações pessoais.</p>
          <h2>1. Informações que recolhemos</h2>
          <br>
          <h3>1.1. Informações fornecidas por Si</h3>
          <div style="padding: 5px">Quando utiliza o nosso Website, podemos recolher informações que você nos fornece 
            voluntariamente, incluindo, mas não se limitando a:<br>
            <div style="padding-left: 20px">
            - Nome e Apelido<br>
            - Endereço de e-mail<br>
            - Informações de contato<br>
            - Outras informações fornecidas quando entra em contacto connosco ou participa de 
            inquéritos ou promoções.<br>
            </div>
          </div>
          <h3>1.2. Informações coletadas automáticamente</h3>
          <div style="padding: 5px">Também podemos coletar informações automaticamente quando você navega no Website, 
            incluindo, mas não se limitando a:<br>
            <div style="padding-left: 20px">
            - Endereço IP<br>
            - Tipo de navegador<br>
            - Páginas visitadas<br>
            - Dados de uso e desempenho<br>
            </div>
          </div>
          <br>
          <h2>2. Uso das Informações</h2>
          <br>
          <h3>2.1. Utilização Interna</h3>
          <div style="padding: 5px">As informações que recolhemos podem ser utilizadas internamente para:<br>
            <div style="padding-left: 20px">
            - Personalizar a sua experiência no nosso website<br>
            - Fornecer informações, produtos ou serviços solicitados<br>
            - Melhorar o nosso Website e os nossos serviços<br>
            - Enviar comunicações de marketing ou informativas, se você optar por receber tais comunicações.<br>
            </div>
          </div>
          <h3>2.2. Compartilhamento com Terceiros</h3>
          <p>Não iremos vender, alugar ou trocar as suas informações pessoais com terceiros 
            sem o seu consentimento, exceto quando exigido por lei ou conforme descrito nesta Política de Privacidade.</p>
          <br>
          <h2>3. Cookies e tecnologias semelhantes</h2>
          <p>O nosso website pode utilizar cookies e outras tecnologias semelhantes para coletar informações 
            sobre as suas preferências e atividades online. Essas informações são utilizadas para melhorar a 
            sua experiência no nosso website e personalizar o conteúdo exibido.</p>
          <br>
          <h2>4. Segurança dos Dados</h2>
          <p>Estamos empenhados em proteger as suas informações pessoais e implementamos medidas 
            de segurança adequadas para evitar acesso não autorizado, divulgação ou alteração das suas informações.</p>
          <br>
          <h2>5. Seus direitos de Proteção de Dados</h2>
          <p>De acordo com as leis de proteção de dados aplicáveis, você tem o direito de acessar, 
            corrigir, excluir ou restringir o processamento das suas informações pessoais. Se você deseja exercer esses direitos ou tiver 
            dúvidas sobre a nossa Política de Privacidade, entre em contacto connosco através dos dados fornecidos na seção de contacto.</p>
          <br>
          <h2>6. Alterações a esta política de privacidade</h2>
          <p>Podemos atualizar esta Política de Privacidade periodicamente para refletir mudanças nas 
            nossas práticas de privacidade. Recomendamos que reveja esta página regularmente para estar 
            ciente de quaisquer alterações.</p>
          <br>
          <h2>7. Contacto</h2>
          <p>Se tiver alguma dúvida ou preocupação sobre a nossa Política de Privacidade, entre em contacto 
            connosco em <b>info@smartsurf.pt</b>.</p>
          <br>
          <p style="text-align: center"><b>SmartSurf</b> - Todos os direitos reservados.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { bus } from '../../main'

export default {
  data: () => ({
    showBalloon: true,
    dialog: false,
    items: [],
  }),
  created () {
    bus.$on('privacyPolicyDialog', () => { console.log("ai ui"); this.openDialog() })
  },
  methods: {
    toggleBalloon() {
      this.showBalloon = !this.showBalloon
      this.openDialog()
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
  },
}
</script>

<style>
.balloon-container {
  min-width: 75%;
  text-align: center;
  position: fixed;
  /*right: 20px;*/
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  animation: slideIn 1s ease-out;
}
.balloon-message {
  font-size: 14px; color: #333;
}

@keyframes slideIn {
  from {
    transform: scale(0); /* Start with the balloon scaled to zero */
  }
  to {
    transform: scale(1); /* End with the balloon fully scaled (1) */
  }
}
</style>