<template>
  <span>
    <v-chip class="ma-1" color="blue lighten-5" text-color="black" @click="dialog = true">
      <v-avatar left class="blue darken-4" style="color: white !important">
        <v-icon small>mdi-plus</v-icon>
      </v-avatar>
      Adicionar
    </v-chip>
    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card :loading="loading">
        <v-card-title>Adicionar equipamento à obra</v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-autocomplete v-model="workEquip.IDEquipment" label="Select equipment"
              :items="list"
              item-text="SerialNumber"
              item-value="ID"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.Brand"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.Model"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-nas</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="add()">Adicionar</v-btn>
          <v-btn text @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    idWork: Number
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    workEquip: {},
    list: [],
  }),
  watch: {
    dialog: function (val) {
      if(val) {
        this.workEquip.IDWork = this.idWork
        this.getEquipments()
      }
    },
  },
  methods: {
    getEquipments: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/equipments')
        //.get('http://localhost:13007/api/v1/equipments')
        .then((response) => {
          this.loading = false
          this.list = response.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter equipamentos'})
        })
    },
    add: function () {
      this.loading = true
      httpClient
        .post('/mix/api/v1/workequipment', this.workEquip)
        //.post('http://localhost:13007/api/v1/workequipment', this.workEquip)
        .then(() => {
          this.loading = false
          bus.$emit('refreshWorkEquipments', null)
          bus.$emit('snackBarMsg', {error: false, msg: 'Equipamente adicionar à obra'})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao tentar adicionar equipamente à obra'})
        })
    }
  }
}
</script>