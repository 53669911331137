<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable style="max-height: 56px;">
    <v-card :loading="loading">
      <v-toolbar flat dark color="primary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ pck.Name }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout row>
          <v-flex grow>
            <v-card class="my-2">
              <v-card-title>
                <div class=".display-3">{{ pck.Description }}</div>
                <v-spacer></v-spacer>
                <v-chip @click="openUpdateDialog()"
                  class="ma-1" color="blue lighten-5" text-color="black">
                  <v-avatar left class="blue darken-4" style="color: white !important">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-avatar>
                  <span>Edit</span>
                </v-chip>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="6" md="6" lg="6">
                    <span class="font-weight-medium">Price:</span> {{ pck.Price }}
                  </v-col>
                  <v-col cols="6" sm="6" md="6" lg="6">
                    <span class="font-weight-medium">Total usage:</span> {{ pck.TotalUsage }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="my-1">
              <v-card-title>
                <div class=".display-3">Associated Services</div>
                <v-spacer></v-spacer>
                <v-chip class="ma-1" color="blue lighten-5" text-color="black" @click="openAssSrvDialog({})">
                  <v-avatar left class="blue darken-4" style="color: white !important">
                    <v-icon small>mdi-plus</v-icon>
                  </v-avatar>
                  Service
                </v-chip>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="columns"
                  :items="rows"
                  :search="search"
                  :items-per-page="5"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right'
                  }"
                >
                  <template v-slot:item.Name="{ item }">
                    <v-chip class="ma-1" color="info" outlined>
                      <v-icon left>mdi-package-variant</v-icon> {{ item.Name }}
                    </v-chip>
                  </template>
                  <template v-slot:item.options="{ item }">
                    <v-btn icon @click="openAssSrvDialog(item)">
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="confirmDel(item)"
                      v-if="item.DeletedAt == null">
                      <v-icon color="info">mdi-delete</v-icon>
                    </v-btn>
                    <v-chip v-if="item.DeletedAt != null"
                      class="ma-1" small color="red" text-color="white">
                      DELETED
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <PackageAddService />
    <PackageServiceDeleteConfirm />
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import PackageAddService from './PackageAddService'
import PackageServiceDeleteConfirm from './PackageServiceDeleteConfirm'

export default {
  data () {
    return {
      pck: {},
      packageServices: {},
      customerList: [],
      dialog: false,
      loading: false,
      confirmDelete: false,
      rows: [],
      search: '',
      columns: [
        { text: 'Name', align: 'left', value: 'Name' },
        { text: 'Description', align: 'left', value: 'Description' },
        { text: 'Quantity', align: 'left', value: 'Quantity' },
        { text: 'Price (Unit)', align: 'left', value: 'Price' },
        { text: 'Options', sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5]
    }
  },
  created () {
    bus.$on('openPackageDialog', () => { this.dialog = true })
    bus.$on('refreshPackageDetails', () => this.get())
    bus.$on('refreshPackageServices', () => this.getPackageServices())
    bus.$on('packageView', (pck) => { 
      this.pck = pck
      this.get()
      this.getPackageServices()
    })
  },
  methods: {
    get() {
      httpClient
        .get('/package/api/v1/package/' + this.pck.ID)
        //.get('http://localhost:13007/api/v1/package/' + this.pck.ID)
        .then((response) => {
          this.pck = response.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting package details'})
        })
    },
    getPackageServices() {
      this.loading = true
      httpClient
        .get('/package/api/v1/package/' + this.pck.ID + '/services')
        //.get('http://localhost:13007/api/v1/package/' + this.pck.ID + '/services')
        .then((resp) => {
          this.loading = false
          this.packageServices = resp.data
          this.rows = resp.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting package services'})
          this.loading = false
        })
    },
    save () {
      this.loading = true
      httpClient
        .put('/package/api/v1/package/' + this.package.ID, this.package)
        .then((response) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package successfully updated'})
          this.lesson = response.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating package'})
        })
    },
    openUpdateDialog: function () {
      bus.$emit('pckDialog', {isNew: false, pck: this.pck})
    },
    openAssSrvDialog (srv) {
      bus.$emit('openAssociateServiceDialog', {srv: srv, pck: this.pck})
    },
    confirmDel (srv) {
      bus.$emit('openPackageServiceDeleteConfirm', {srv: srv, pck: this.pck})
    }
  },
  components: {
    PackageAddService,
    PackageServiceDeleteConfirm
  }
}
</script>

<style>
/* BUG open - https://github.com/vuetifyjs/vuetify/issues/8606 */
header {
  max-height: 65px;
}
</style>