<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title>
      <v-icon left>mdi-nas</v-icon> Equipment
      <Create :idWork="this.idWork" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Procurar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <div class="layout row" style="flex-wrap: wrap !important;">
        <div class="flex" v-for="i in fWorkItems" :key="i.ID">
          <v-card :loading="loading" class="mx-1 my-2" outlined>
            <v-card-title>{{ i.Brand }} {{ i.Model }}</v-card-title>
            <v-card-text>
              <v-chip @click="openWorkTimeDialog(i.IDEquipment)"
                class="ma-1"
                color="green lighten-5"
                text-color="black"
              >
                <v-avatar left
                  class="green darken-4"
                  style="color: white !important"
                >
                  <v-icon small>mdi-timer</v-icon>
                </v-avatar>
                <span>Registar horas</span>
              </v-chip>
              <v-chip @click="openWorkFuelDialog(i.IDEquipment)"
                class="ma-1"
                color="green lighten-5"
                text-color="black"
              >
                <v-avatar left
                  class="green darken-4"
                  style="color: white !important"
                >
                  <v-icon small>mdi-gas-station-outline</v-icon>
                </v-avatar>
                <span>Encher tanque</span>
              </v-chip>
              <v-chip
                class="ma-1"
                color="green lighten-5"
                text-color="black"
                @click="openWorkMaintenanceDialog(i.IDEquipment)"
              >
                <v-avatar left
                  class="green darken-4"
                  style="color: white !important"
                >
                  <v-icon small>mdi-tools</v-icon>
                </v-avatar>
                <span>Registar manutenção</span>
              </v-chip>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link v-bind:to="'/equipment/' + i.IDEquipment">
                <v-chip
                  class="mx-1"
                  color="blue lighten-5"
                  text-color="black"
                >
                  <v-avatar
                    left
                    class="blue darken-4"
                    style="color: white !important"
                  >
                    <v-icon small>mdi-forward</v-icon>
                  </v-avatar>
                  Ver
                </v-chip>
              </router-link>
              <v-chip @click="remove(i.IDEquipment)"
                class="mx-1"
                color="red lighten-5"
                text-color="black"
              >
                <v-avatar
                  left
                  class="red darken-4"
                  style="color: white !important"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-avatar>
                Remover
              </v-chip>
            </v-card-actions>
          </v-card>
          <!--<Details :idWork="i.IDWork" :idEquipment="i.IDEquipment" :brand="i.Brand" :model="i.Model" />-->
        </div>
      </div>
      <TimeDialog />
      <EquipFuelDialog v-if="true" />
      <MaintenanceDialog />
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import Create from './Create'
//import Details from './Details'
import TimeDialog from './TimeDialog'
import EquipFuelDialog from './EquipFuelDialog'
import MaintenanceDialog from './MaintenanceDialog'

export default {
  props: {
    idWork: Number
  },
  data () {
    return {
      loading: false,
      search: '',
      fWorkItems: [],
    }
  },
  watch: {
    search: function (val) {
      this.fWorkItems = this.rows.filter(function (a) {
        return a.Name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
    },
  },
  created () {
    bus.$on('refreshWorkEquipments', () => { this.getAll() })
    this.getAll()
  },
  beforeDestroy () {
    bus.$off('refreshWorkEquipments', null)
  },
  methods: {
    openWorkTimeDialog(idEquip) {
      bus.$emit('openWorkEquipTimeDialog', {idWork: this.idWork, idEquipment: idEquip})
    },
    openWorkFuelDialog(idEquip) {
      bus.$emit('openWorkEquipFuelDialog', {idWork: this.idWork, idEquipment: idEquip})
    },
    openWorkMaintenanceDialog(idEquip) {
      bus.$emit('openWorkMaintenanceDialog', {idWork: this.idWork, idEquipment: idEquip})
    },
    getAll () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/workequipment/' + this.idWork)
        //.get('http://localhost:13007/api/v1/workequipment/' + this.idWork)
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
          this.fWorkItems = this.rows
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting work items'})
        })
    },
    remove (idEquip) {
      this.loading = true
      httpClient
        .delete('/mix/api/v1/workequipment/' + this.idWork + '/' + idEquip)
        //.delete('http://localhost:13007/api/v1/workequipment/' + this.idWork + '/' + idEquip)
        .then(() => {
          this.loading = false
          bus.$emit('refreshWorkEquipments')
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error deleting work staffs'})
        })
    }
  },
  components: {
    Create,
    TimeDialog,
    EquipFuelDialog,
    MaintenanceDialog
  }
}
</script>