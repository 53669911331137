var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-package-variant")]),_vm._v(" Packages "),_c('v-chip',{staticClass:"ma-1",attrs:{"color":"blue lighten-5","text-color":"black"},on:{"click":function($event){return _vm.openNewDialog()}}},[_c('v-avatar',{staticClass:"blue darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1),_vm._v(" Create ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.rows,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"footer-props":{
        itemsPerPageOptions: _vm.rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.Name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Name)+" ")]}},{key:"item.Description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Description)+" ")]}},{key:"item.options",fn:function({ item }){return [_c('v-chip',{staticClass:"mx-1",attrs:{"color":"blue lighten-5","text-color":"black"},on:{"click":function($event){return _vm.view(item)}}},[_c('v-avatar',{staticClass:"blue darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-app")])],1),_vm._v(" View ")],1),(item.DeletedAt === null)?_c('v-chip',{staticClass:"mx-1",attrs:{"color":"red lighten-5","text-color":"black"},on:{"click":function($event){return _vm.archive(item)}}},[_c('v-avatar',{staticClass:"red darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1),_vm._v(" Archive ")],1):_vm._e(),(item.DeletedAt !== null)?_c('v-chip',{staticClass:"mx-1",attrs:{"color":"green lighten-5","text-color":"black"},on:{"click":function($event){return _vm.unArchive(item.ID)}}},[_c('v-avatar',{staticClass:"green darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive-outline")])],1),_vm._v(" Activate ")],1):_vm._e()]}}])})],1),_c('PackageView'),_c('PackageDialog'),_c('PackageDeleteConfirm')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }