<template>
  <div>
    <v-fab-transition>
      <v-btn
        style="top: 0px; right: 70px;"
        absolute
        color="primary"
        fab
        medium
        dark
        top
        right
        @click="openPrivacyPolicyDialog()"
      >
        <v-icon>mdi-gavel</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        v-if="!this.isLoggedIn"
        style="top: 0px; right: 0px;"
        absolute
        color="primary"
        fab
        medium
        dark
        top
        right
        @click="openLoginDialog()"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-container class="nmContainer pa-5">
      <v-row justify="center" class="nmVRow">BE SMART</v-row>
      <v-row justify="center" class="nmVRow2 pa-2 font-weight-thin">Work less, surf more...</v-row>
    </v-container>
    <!--<v-container>
      <v-row justify="center" class="nmVRow2 pa-1 font-weight-thin">Already a <span class="mx-1 font-weight-regular">SmartSurf</span> customer ?</v-row>
      <v-row justify="center" class="pa-1"><v-btn rounded color="primary" dark >Login</v-btn></v-row>
      <v-row justify="center" class="nmVRow2 pa-1 font-weight-thin">Start now simplifying your business using <span class="mx-1 font-weight-regular">SmartSurf</span></v-row>
      <v-row justify="center" class="pa-1"><v-btn rounded color="secondary" dark href="/signup">Register</v-btn></v-row>
    </v-container>-->
    <v-container class="nmContainer pa-6">
      <v-row justify="center" class="nmVRow">Want to know more ?</v-row>
      <v-row justify="center" class="nmVRow2 pa-2 font-weight-thin">SmartSurf has a goal to make you work less and surf more!</v-row>
    </v-container>
    <v-carousel cycle height="300" hide-delimiters>
      <v-carousel-item :src="require('@/assets/report.jpg')"></v-carousel-item>
      <v-carousel-item :src="require('@/assets/praia.jpg')"></v-carousel-item>
      <v-carousel-item :src="require('@/assets/stats.jpg')"></v-carousel-item>
      <v-carousel-item :src="require('@/assets/praia_ondas.jpg')"></v-carousel-item>
    </v-carousel>
    <v-card class="elevation-0">
      <v-container style='font: 400 15px/1.42857143 "Roboto", sans-serif; padding: 20px;'>
        <v-row justify="center" style='font: 300 32px/36px "Roboto", sans-serif'>
          Features
        </v-row>
        <v-row justify="center" style='font-size: 20px; line-height: 24px; padding-top: 10px;' class="font-weight-light text-center">
          SmartSurf was created to cover your needs, offerering all the specialized tools and database for a successful management. With a simple click, you can completely access your company information anywhere, on your laptop, tablet, or smartphone.
        </v-row>
      </v-container>
      <v-container>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="4"
            v-for="(item, i) in items"
            :key="i"
          >
            <v-card>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title>
                    <v-icon large color="blue lighten-1">
                      {{ item.icon }}
                    </v-icon>
                  </v-card-title>
                  <v-card-subtitle class="title font-weight-light">{{ item.title }}</v-card-subtitle>
                  <v-card-text style="min-height: 80px;">
                    <div v-text="item.description"></div>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container class="nmContainer pa-6">
      <v-row justify="center" class="nmVRow">Our Team</v-row>
      <v-row justify="center" class="nmVRow2 pa-2 font-weight-light text-center">We are a young, open-minded and ambitious team, ready to battle against the hardest challenges and come up with a solution. Each one of us has a specific task in this complex platform, making the perfect combination for a great result.</v-row>
    </v-container>
    <v-container class="nmContainer pa-6">
      <v-row justify="center" class="nmVRow">
        Contact information
      </v-row>
      <v-row justify="center" class="nmVRow2 pa-4 font-weight-thin">
        We'll always get back to you within 24 hours.
      </v-row>
      <v-row justify="center" class="nmVRow2 pa-2 font-weight-light text-center">
        <v-icon color="blue lighten-1">mdi-cellphone</v-icon> +351 93 496 10 28
      </v-row>
      <v-row justify="center" class="nmVRow2 pa-2 font-weight-light text-center">
        <v-icon color="blue lighten-1">mdi-at</v-icon> info@smartsurf.pt
      </v-row>
      <v-row justify="center" class="nmVRow2 pa-2 font-weight-light text-center">
        <v-icon color="blue lighten-1">mdi-map-marker</v-icon> Lisbon, Portugal
      </v-row>
    </v-container>
    <LoginDialog />
    <PrivacyPolicyDialog />
  </div>
</template>

<script>
import { bus } from '../../main'
import LoginDialog from '../General/LoginDialog'
import PrivacyPolicyDialog from './PrivacyPolicy.vue'

export default {
  data: () => ({
    items: [
      {
        icon: 'mdi-currency-eur',
        title: 'Price',
        description: 'An ajustabled fee according to your business.',
      },
      {
        icon: 'mdi-finance',
        title: 'Monitoring',
        description: 'Manage all your lessons, equipments and students.',
      },
      {
        icon: 'mdi-thumb-up',
        title: 'Usability',
        description: 'The clear and simple layout supports your organization.',
      },
      {
        icon: 'mdi-image-filter-center-focus-strong',
        title: 'Focus',
        description: 'Check all your information quickly and with few interactions.',
      },
      {
        icon: 'mdi-chart-bar-stacked',
        title: 'Analytics',
        description: 'Have a complete overview and control of your financial results.',
      },
    ],
    isLoggedIn: false,
  }),
  mounted () {
    bus.$on('loggedIn', () => { 
      this.isLoggedIn = true
    })
    bus.$on('logout', () => {
      this.isLoggedIn = false
    })
    if (this.$store.state.token !== null) {
      this.isLoggedIn = true
    }
    if (this.$route.params.login == "true") {
      this.openLoginDialog()
    }
    //this.$router.replace('/')
    /*if(this.$store.state.token !== null) {
      this.$router.push({ name: 'User', query: { redirect: '/user' } })
    }*/
  },
  methods: {
    openLoginDialog() {
      bus.$emit("loginDialog")
    },
    openPrivacyPolicyDialog() {
      bus.$emit("privacyPolicyDialog")
    }
  },
  components: {
    LoginDialog,
    PrivacyPolicyDialog
  }
}
</script>

<style>
.nmContainer {
  font: 400 15px/1.42857143 "Roboto", sans-serif;
}
.nmVRow {
  font: 300 32px/36px "Roboto", sans-serif;
}
.nmVRow2 {
  font-size: 20px;
  line-height: 24px;
}
.nmRowPadding {
  padding: 20px;
}
</style>
