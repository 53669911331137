<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-room-service-outline</v-icon> Services
      <v-chip
        class="ma-1"
        color="blue lighten-5"
        text-color="black"
        @click="openNewDialog()"
      >
        <v-avatar
          left
          class="blue darken-4"
          style="color: white !important"
        >
          <v-icon small>mdi-plus</v-icon>
        </v-avatar>
        Create
      </v-chip>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :search="search"
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      > 
        <template v-slot:item.options="{ item }">
          <v-chip v-if="isFieldEmpty(item.DeletedAt)" @click="openDialog(item)"
            class="mx-1" color="blue lighten-5" text-color="black"
          >
            <v-avatar left class="blue darken-4" style="color: white !important">
              <v-icon small>mdi-pencil</v-icon>
            </v-avatar>
            Edit
          </v-chip>
          <v-chip v-if="isFieldEmpty(item.DeletedAt)" @click="archive(item.ID)"
            class="mx-1" color="red lighten-5" text-color="black"
          >
            <v-avatar left class="red darken-4" style="color: white !important">
              <v-icon small>mdi-archive</v-icon>
            </v-avatar>
            Archive
          </v-chip>
          <v-chip v-if="!isFieldEmpty(item.DeletedAt)" @click="unArchive(item.ID)"
            class="mx-1" color="green lighten-5" text-color="black"
          >
            <v-avatar left class="green darken-4" style="color: white !important">
              <v-icon small>mdi-archive-outline</v-icon>
            </v-avatar>
            Activate
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialog" scrollable max-width="70%">
      <v-card :loading="loadingUpdate">
        <v-card-title><span v-if="isNew">Create service</span><span v-if="!isNew">Update service</span></v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <v-text-field
            v-model="selectedService.Name"
            label="Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="selectedService.Description"
            label="Description"
            required
          ></v-text-field>
          <v-text-field
            v-model="selectedService.Price"
            label="Price"
            required
          ></v-text-field>
          <v-autocomplete
            v-model="selectedService.Type"
            :items="serviceTypes"
            item-text="Type"
            item-value="Type"
            label="Service Type"
            placeholder="Select type.."
            autocomplete="nope"
          ></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="create()" v-if="isNew">Create</v-btn>
          <v-btn color="blue darken-1" text @click="update()" v-if="!isNew">Save</v-btn>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      loadingUpdate: false,
      search: '',
      rows: [],
      columns: [
        { text: 'Name', align: 'left', value: 'Name' },
        { text: 'Price', align: 'left', value: 'Price' },
        { text: 'Type', align: 'left', value: 'Type' },
        { text: 'Amount sold', align: 'left', value: 'TotalUsage' },
        { text: 'Options', sortable: false, value: 'options', width: '250px' }
      ],
      rowsPerPageItems: [5, 10, 25, 50],
      serviceTypes: [],
      selectedService: {},
      toBeDeletedSrv: {},
      dialog: false,
      dialogDeleteConfirm: false,
      isNew: false,
      apiVersion: 'v1',
    }
  },
  mounted: function () {
    this.getAll()
    this.getTypes()
    bus.$on('refreshServiceList', () => { this.getAll() })
  },
  beforeDestroy () {
    bus.$off('refreshServiceList', null)
  },
  methods: {
    isFieldEmpty(val) {
      return val === undefined || val === "0001-01-01T00:00:00Z" || val === ''
    },
    getAll: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/services/historical')
        //.get('http://localhost:13007/api/v1/services/historical')
        .then((resp) => {
          this.loading = false
          this.rows = []
          if (resp.data !== null) {
            this.rows = resp.data
          }
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting services'})
        })
    },
    getTypes: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/services/types')
        //.get('http://localhost:13007/api/v1/services/types')
        .then((r) => {
          this.loading = false
          this.serviceTypes = []
          if (r.data !== null) {
            this.serviceTypes = r.data
          }
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting services'})
        })
    },
    create: function () {
      this.loading = true
      httpClient
        .post('/package/api/v1/service', this.selectedService)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service created'})
          this.dialog = false
          bus.$emit('refreshServiceList', {})
          this.getAll()
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating service'})
        })
    },
    update: function () {
      this.loading = true
      httpClient
        .put('/package/api/v1/service/' + this.selectedService.ID, this.selectedService)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service updated'})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating service'})
        })
    },
    confirm: function (srv) {
      this.toBeDeletedSrv = srv
      this.dialogDeleteConfirm = true
    },
    archive: function (id) {
      this.loading = true
      httpClient
        .delete('/package/api/v1/service/' + id)
        //.delete('http://localhost:13007/api/v1/service/' + id)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service archived'})
          bus.$emit('refreshServiceList', {})
          this.dialogDeleteConfirm = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error archiving service'})
        })
    },
    unArchive: function (id) {
      this.loading = true
      httpClient
        //.put('/package/api/v1/service/' + id + "/undelete", {})
        .put('http://localhost:13007/api/v1/service/' + id + "/undelete", {})
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service activated'})
          bus.$emit('refreshServiceList', {})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating service'})
        })
    },
    openDialog: function (service) {
      this.isNew = false
      this.dialog = true
      this.selectedService = service
    },
    openNewDialog: function () {
      this.isNew = true
      this.dialog = true
      this.selectedService = {}
    },
  }
}
</script>
