<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-nas</v-icon> <span>{{ this.e.ID }} - {{ this.e.Brand }} {{ this.e.Model }}</span></div>
            <v-spacer></v-spacer>
            <EquipmentDialog :action="'update'" :details="e" />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <b>Tipo:</b> <span>{{ this.e.Type }}</span><br />
                <b>Matrícula:</b> <span>{{ this.e.PlateNumber }}<br />
                <b>Data Matrícula:</b> {{ this.e.PlateDate }}</span><br />
                <b>Número de Série:</b> <span>{{ this.e.SerialNumber }}</span><br />
                <b>Notas:</b> <span>{{ this.e.Notes }}</span><br />
                <br />
                <b>Obra:</b> <span><i>Mostrar obra onde o equipomento se encontra..</i></span><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loadingFuels" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-gas-station-outline</v-icon> <span>Histórico de Abastecimento</span></div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div class="flex" v-for="w in workEquipFuel" :key="w.ID">
                  {{ w.WorkName }} - <b>{{ Math.round(( parseInt( w.TotalLiters.replace(".", ",") ) + Number.EPSILON) * 100) / 100 }}</b> litros
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loadingTimes" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-run</v-icon> <span>Histórico de Funcionamento</span></div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div class="flex" v-for="w in workEquipTime" :key="w.ID">
                  {{ w.WorkName }} - <b>{{ w.TotalHours }} horas</b>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loadingWorks" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-gas-station-outline</v-icon> <span>Histórico de Obras</span></div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div class="flex" v-for="w in works" :key="w.ID">
                  {{ w.Name }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
import EquipmentDialog from './EquipmentDialog'

export default {
  data () {
    return {
      e: {},
      works: [],
      workEquipTime: [],
      workEquipFuel: [],
      loading: false,
      loadingFuels: false,
      loadingTimes: false,
      loadingWorks: false,
    }
  },
  created () {
    this.e.ID = +this.$route.params.id
    bus.$on('refreshEquipment', () => { this.get() })
    this.get()
    this.getWorks()
    this.getEquipTime()
    this.getEquipFuel()
  },
  beforeDestroy () {
    bus.$off('refreshEquipment', null)
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm:ss')
    },
    get () {
      this.loading = true
      httpClient
        .get('/equipment/api/v1/equipment/' + this.e.ID)
        //.get('http://localhost:13011/api/v1/equipment/' + this.e.ID)
        .then((response) => {
          this.loading = false
          this.e = response.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter equipamento'})
        })
    },
    getWorks () {
      this.loadingWorks = true
      httpClient
        .get('/equipment/api/v1/works/equipment/' + this.e.ID)
        //.get('http://localhost:13007/api/v1/works/equipment/' + this.e.ID)
        .then((r) => {
          this.loadingWorks = false
          this.works = []
          if (r.data !== null) {
            this.works = r.data
          }
        })
        .catch(() => {
          this.loadingWorks = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter equipamento'})
        })
    },
    getEquipTime () {
      this.loadingTimes = true
      httpClient
        .get('/mix/api/v1/workequiptimes/' + this.e.ID)
        //.get('http://localhost:13007/api/v1/workequiptimes/' + this.e.ID)
        .then((r) => {
          this.loadingTimes = false
          this.workEquipTime = []
          if (r.data !== null) {
            this.workEquipTime = r.data
          }
        })
        .catch(() => {
          this.loadingTimes = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao carregar detalhes do equipamento'})
        })
    },
    getEquipFuel () {
      this.loadingFuels = true
      httpClient
        .get('/mix/api/v1/workequipmentfuels/' + this.e.ID)
        //.get('http://localhost:13007/api/v1/workequipmentfuels/' + this.e.ID)
        .then((r) => {
          this.loadingFuels = false
          this.workEquipFuel = []
          if (r.data !== null) {
            this.workEquipFuel = r.data
          }
        })
        .catch(() => {
          this.loadingFuels = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao carregar detalhes do equipamento'})
        })
    },
  },
  components: {
    EquipmentDialog
  }
}
</script>