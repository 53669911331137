<template>
  <v-app class="smartsurf">
    <SideBar v-if="showMenu" />
    <v-sheet class="overflow-hidden" style="position: relative;">
      <v-container fluid>
        <v-main v-bind:style="{ 'margin-left': marginLeft }"
          style="min-height: 600px; padding-top: 0px; margin-bottom: 50px;">
          <!--<Breadcumbs v-if="showMenu" style="margin-bottom: 10px" />-->
          <router-view/>
        </v-main>
        <SnackBar />
      </v-container>
    </v-sheet>
    <Footer />
  </v-app>
</template>

<script>
import { bus } from './main'
import SnackBar from './components/General/SnackBar'
import Footer from './components/General/Footer'
import SideBar from './components/General/SideBar'
//import Breadcumbs from './components/General/Breadcumbs.vue'

export default {
  name: 'App',
  data () {
    return {
      marginLeft: '55px',
      showMenu: false,
    }
  },
  computed: {
    computedWidth() { return this.width }
  },
  created () {
    document.title = "SmartSurf"
    bus.$on('loggedIn', () => {
      this.checkUser()
      this.marginLeft = "55px"
    })
    bus.$on('logout', () => {
      this.checkUser()
      this.marginLeft = "0px"
    })
    bus.$on('menuMove', (marginLeft) => { this.marginLeft = marginLeft })
    this.checkUser()
  },
  methods: {
    checkUser: function () {
      if(this.$store.state.token !== null) {
        this.showMenu = true
      } else {
        this.marginLeft = "0px"
        this.showMenu = false
      }
    }
  },
  components: {
    SideBar,
    SnackBar,
    Footer,
    //Breadcumbs,
  }
};
</script>

<style>
.smartsurf .s-chip {
  padding: 0 5px;
}
::-webkit-scrollbar
{
	width: 12px; /* for vertical scrollbars */
	height: 12px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track
{
	background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb
{
	background: rgba(0, 0, 0, 0.5);
}
.v-toolbar__extension {
  padding: 0px !important;
  height: unset !important; 
}
.v-breadcrumbs {
  padding: 6px 12px !important;
}
.nm-NoPaddingBot {
  padding-bottom: 0;
}
.nm-NoPaddingTopBot {
  padding-top: 0;
  padding-bottom: 0;
}
</style>