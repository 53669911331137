<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-home-modern</v-icon> <span>{{ this.work.Name }}</span></div>
            <v-spacer></v-spacer>
            <WorkDialog :action="'update'" :details="work" />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <span class="font-weight-medium">Inicio da obra:</span> {{ formatDate(this.work.DateTime) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <span class="font-weight-medium">Latitude:</span> {{ this.work.Latitude }}
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <span class="font-weight-medium">Longitude:</span> {{ this.work.Longitude }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <WorkEquipmentList :idWork="this.work.ID" />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <WorkStaffList :idWork="this.work.ID" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
import WorkDialog from './WorkDialog'
import WorkStaffList from '../WorkStaff/List'
import WorkEquipmentList from '../WorkEquipment/List'

export default {
  data () {
    return {
      work: {},
      loading: false
    }
  },
  created () {
    this.work.ID = +this.$route.params.id
    this.get()
    bus.$on('refreshWorkDetails', () => { this.get() })
  },
  beforeDestroy () {
    bus.$off('refreshWorkDetails', null)
  },
  methods: {
    formatDate: function (dateToFormat) {
      //return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY')
    },
    get () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/work/' + this.work.ID)
        //.get('http://localhost:13007/api/v1/work/' + this.work.ID)
        .then((response) => {
          this.loading = false
          this.work = response.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao tentar carregar obra'})
        })
    },
  },
  components: {
    WorkDialog,
    WorkStaffList,
    WorkEquipmentList
  }
}
</script>