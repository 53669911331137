<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-train-car</v-icon> <span><span v-if="!isOldSmartSurf()">{{ t.ID }} - </span>{{ t.Brand }} {{ t.Model }}</span></div>
            <v-spacer></v-spacer>
            <TransportDialog :action="'update'" :details="t" />
          </v-card-title>
          <v-card-text>
            <v-row v-if="!isOldSmartSurf()">
              <v-col cols="12" sm="12" md="12" lg="12">
                <b>Marca:</b> <span>{{ t.Brand }}</span><br />
                <b>Modelo:</b> <span>{{ t.Model }}</span><br />
                <b>Matrícula:</b> <span>{{ t.PlateNumber }}<br />
                <b>Data Matrícula:</b> {{ t.PlateDate }}</span><br />
                <b>Número de Série:</b> <span>{{ t.SerialNumber }}</span><br />
                <b>Tipo:</b> <span>{{ t.Type }}</span><br />
                <br />
                <b>Empregado:</b> <span><i>Mostrar empregado que usa o carro..</i></span><br />
              </v-col>
            </v-row>
            <v-row v-if="isOldSmartSurf()">
              <v-col cols="12" sm="12" md="12" lg="12">
                <b>Marca:</b> <span>{{ t.Brand }}</span><br />
                <b>Número de lugares:</b> <span>{{ t.SeatNumber }}</span><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import TransportDialog from './TransportDialog'

export default {
  data () {
    return {
      t: {},
      staffs: [],
      loading: false,
      loadingStaffs: false,
    }
  },
  created () {
    this.t.ID = +this.$route.params.id
    bus.$on('refreshTransport', () => { this.get() })
    this.get()
    //this.getStaff()
  },
  beforeDestroy () {
    bus.$off('refreshTransport', null)
  },
  methods: {
    isOldSmartSurf () {
      if(this.db !== "manuelpedrosousa") {
        return true
      }
      return false
    },
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm:ss')
    },
    get () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/transport/' + this.t.ID)
        //.get('http://localhost:13007/api/v1/transport/' + this.e.ID)
        .then((r) => {
          this.loading = false
          this.t = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter equipamento'})
        })
    },
    getStaff () {
      this.loadingStaffs = true
      /*httpClient
        .get('/mix/api/v1/staffs/transport/' + this.t.ID)
        //.get('http://localhost:13007/api/v1/staffs/transport/' + this.e.ID)
        .then((r) => {
          this.loadingStaffs = false
          this.staffs = []
          if (r.data !== null) {
            this.staffs = r.data
          }
        })
        .catch(() => {
          this.loadingStaffs = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter equipamento'})
        })*/
    },
  },
  components: {
    TransportDialog
  }
}
</script>