<template>
  <v-card :loading="loading" elevation="1">
    <v-card-title>
      <v-tooltip bottom v-if="!showUsed">
        <template v-slot:activator="{ on }">
          <v-icon left v-on="on" @click="showUsed = !showUsed">mdi-room-service-outline</v-icon>
        </template>
        <span>View used</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showUsed">
        <template v-slot:activator="{ on }">
          <v-icon left v-on="on" @click="showUsed = !showUsed">mdi-room-service</v-icon>
        </template>
        <span>Hide used</span>
      </v-tooltip>
      Services <StudentServiceAdd :studentID="customerID" :buttonLable="'Add'" />
    </v-card-title>
    <v-card-text>
      <div>
        <span color="cyan darken-3" v-for="srv in servicesFiltered" :key="srv.IDStudentService">
          <v-chip
            class="ma-1"
            color="green lighten-5"
            text-color="black"
            v-if="srv.Consumed != 1"
            v-bind:style="{'border': '1px solid #E8F5E9', 'background': 'linear-gradient(to left, #ffffff ' + (100-srv.UsagePercentage) + '%, #E8F5E9 ' + srv.UsagePercentage + '%)'}"
            @click="openStudentServiceDetails(srv)"
          >
            <v-avatar
              left
              class="green darken-4"
              style="color: white !important"
            >
              {{ srv.Quantity }}
            </v-avatar>
            {{ srv.Name }}
            <v-avatar
              right
              style="font-size: 10px"
            >
              {{ srv.UsagePercentage }}%
            </v-avatar>
          </v-chip>
          <v-chip
            class="ma-1"
            color="red lighten-5"
            text-color="black"
            v-if="srv.Consumed == 1"
            @click="openStudentServiceDetails(srv)"
          >
            <v-avatar
              left
              class="red darken-4"
              style="color: white !important"
            >
              {{ srv.Quantity }}
            </v-avatar>
            {{ srv.Name }}
            <v-avatar
              v-if="srv.UsagePercentage > 100"
              right
              style="color: #B71C1C; font-size: 10px; font-weight: bold"
            >
              {{ srv.UsagePercentage }}%
            </v-avatar>
          </v-chip>
        </span>
        <v-chip
          class="ma-1"
          color="yellow lighten-4"
          text-color="black"
          @click="showUsed = !showUsed"
        >
          <v-avatar
            left
            class="yellow darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-dots-horizontal</v-icon>
          </v-avatar>
          <span v-if="!showUsed">Show used</span><span v-if="showUsed">Hide used</span>
        </v-chip>
        <StudentServiceDetailsView />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import StudentServiceAdd from './StudentServiceAdd'
import StudentServiceDetailsView from '../Student/StudentServiceDetailsView'

export default {
  props: {
    customerID: Number
  },
  data () {
    return {
      loading: false,
      showUsed: false,
      search: '',
      services: [],
      servicesFiltered: [],
    }
  },
  watch: {
    showUsed: function (val) {
      if(val) {
        this.servicesFiltered = this.services
      } else {
        this.servicesFiltered = this.services.filter(s => s.Consumed !== true)
      }
    }
  },
  mounted: function () {
    bus.$on('refreshStudentServices', () => { this.getCustomerServices(this.customerID) })
    this.getCustomerServices(this.customerID)
  },
  beforeDestroy () {
    bus.$off('refreshStudentServices', null)
  },
  methods: {
    getCustomerServices: function (customerID) {
      this.loading = true
      httpClient
        .get('/package/api/v1/services/student/' + customerID)
        //.get('http://localhost:13004/api/v1/services/student/' + customerID)
        .then((resp) => {
          this.loading = false
          this.services = resp.data
          if(this.showUsed === false) {
            this.servicesFiltered = this.services.filter(pck => pck.Consumed !== true)
            return
          }

          this.servicesFiltered = this.services
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting customer services'})
        })
    },
    openStudentServiceDetails: function (s) {
      bus.$emit('openStudentServiceDetails', {srv: s})
    }
  },
  components: {
    StudentServiceAdd,
    StudentServiceDetailsView
  }
}
</script>
