<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <v-card :loading="loading">
      <v-card-title><span v-if="isNew">Create package</span><span v-if="!isNew">Update package</span></v-card-title>
      <v-card-text>
        <br />
        <v-form v-model="valid">
          <v-text-field
            v-model="pck.Name"
            :rules="nameRules"
            label="Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="pck.Description"
            label="Description"
            required
          ></v-text-field>
          <v-text-field
            v-model="pck.Price"
            label="Price"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="!valid" v-if="isNew" @click="create()">Create</v-btn>
        <v-btn color="primary" text :disabled="!valid" v-if="!isNew" @click="update()">Update</v-btn>
        <v-btn text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data: () => ({
    pck: {},
    isNew: true,
    dialog: false,
    loading: false,
    valid: false,
    nameRules: [
      v => !!v || 'Name is required'
    ]
  }),
  created () {
    bus.$on('pckDialog', (data) => {
      this.pck = data.pck
      this.isNew = data.isNew
      this.dialog = true
    })
  },
  beforeDestroy () {
    bus.$off('pckDialog', null)
  },
  methods: {
    create: function () {
      this.loading = true
      httpClient
        .post('/package/api/v1/package', this.pck)
        //.post('http://localhost:13007/api/v1/package', this.pck)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package successfully created'})
          bus.$emit('refreshPackageList', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating package'})
        })
    },
    update: function () {
      this.loading = true
      httpClient
        .put('/package/api/v1/package/' + this.pck.ID, this.pck)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package updated'})
          bus.$emit('refreshPackageList', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating package'})
        })
    }
  }
}
</script>