<template>
  <div>
    <v-toolbar elevation="1" rounded short>
      <v-toolbar-title>SmartBot</v-toolbar-title>
      <v-spacer></v-spacer>
      <div>{{ getPrettyDate(this.date) }}</div>
    </v-toolbar>

    <v-timeline dense align-top>
      <v-timeline-item v-for="item in items" :key="item.id" :color="item.color" :hide-dot="item.hidedot" small>
        <div v-if="item.type === 'sentence'">
          <p :class="`headline font-weight-light gray--text`">
            <span v-for="(letter, index) in item.letters" :key="item.id + '-' + index">{{ letter }}</span>
            <!--{{ item.sentence }}-->
          </p>
        </div>
      </v-timeline-item>
    </v-timeline>

    <!--<div class="input-container">
      <input v-model="inputBoxMessage" class="input-field" placeholder="Type your message">
      <v-btn class="ma-2" color="primary">
        <v-icon small>mdi-check</v-icon>
      </v-btn>
    </div>-->

    <v-card elevation="0">
      <v-card-text>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.v-timeline-item {
  padding-bottom: 0px !important;
}
.input-container {
  position: fixed;
  bottom: 0;
  width: calc(100% - 90px);
  padding-bottom: 20px;
  background-color: white;
  display: flex;
  align-items: center;
}
.input-field {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
}
.send-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import annyang from 'annyang'
import { bus } from '../../main'

export default {
  data() {
    return {
      loading: false,
      userName: '',
      date: new Date().toISOString().substr(0, 10),
      items: [],
      context: "",
      lesson: {},
      inputBoxMessage: "",
    };
  },
  created () {
    this.userName = this.$store.state.userName
    
    let day = moment.parseZone(this.date).format('YYYY-MM-DD')
    this.items.push({id: Math.random(), hidedot: true, type: 'day', lesson: null, date: day})

    this.addNewSentence(`Welcome ${this.userName}, how can I help you?`, "blue")

    if (annyang) {
      let commands = {
        'hello': this.handleHello,
        'search for *query': this.handleSearch,
        'create *lesson': this.handleCreateLesson,
        'the date is *details': this.handleGivenDate,
        'well done': this.handleGratification,
        'great': this.handleGratification,
        'good job': this.handleGratification,
        'yes *anything': this.handleConfirmation,
        'none': this.handleNone,
      };
      annyang.addCommands(commands);
      annyang.start();

      const self = this;

      // define a fallback function to handle unknown commands
      annyang.addCallback("resultNoMatch", function(userSaid) {
        //console.log(userSaid)
        self.handleUnknown(userSaid)
      })
    }
  },
  beforeDestroy () {
    if (annyang) {
      annyang.abort();
    }
  },
  methods: {
    sendTypeMsg: function() {
      console.log(this.inputBoxMessage)
    },
    handleUnknown: function(command) {
      if(this.context === "create lesson") {
        this.handleGivenDate(command[0])
        return
      }

      this.addNewSentence("I'm so sorry I didn't understand what you said: " + command[0], "red")
      //this.addNewSentence("I'm still learning all about being smart and surf ! So far I can awnser to 'Create lesson'.", "blue")
    },
    handleHello(command) {
      console.log(command)
      this.addNewSentence("Hello :) what can I do for you " + this.userName + "?", "green")
    },
    handleConfirmation(command) {
      console.log(command)

      if (this.context === "confirm lesson date") {
        this.addNewSentence("Great, should we add staffs?", "green")
        this.context = "lesson add staffs?"
        return
      }

      if (this.context === "lesson add staffs?") {
        this.addNewSentence("Let's do it than, which staff should I add?", "green")
        this.context = "lesson adding staff"
        return
      }

      this.addNewSentence("What can I do to help?")
    },
    handleCreateLesson(command) {
      console.log(command)
      this.addNewSentence("I will create a lesson for you, could you please tell me the date?", "green")
      this.context = "create lesson"
    },
    handleGivenDate(command) {
      console.log(command)

      let sentence = {}
      sentence.sentence = command
      httpClient
        .get('/mix/api/v1/smartbot/check/sentence?sentence=' + sentence)
        //.post('http://localhost:13007/api/v1/smartbot/check/sentence?sentence=' + sentence)
        .then((data) => {
          this.loading = false
          console.log(data)
          bus.$emit('snackBarMsg', {error: false, msg: 'Frase analisada'})
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao analisar frase'})
        })

      this.addNewSentence("The date is " + command + ", is this correct?", "yellow")
      this.context = "confirm lesson date"
    },
    handleSearch(command) {
      console.log(command)
    },
    handleGratification(command) {
      console.log(command)
      this.addNewSentence("Thank you for the compliment!", "green")

      httpClient
        .get("https://api.chucknorris.io/jokes/random")
        .then(response => {
          this.addNewSentence(response.data.value)
        })
        .catch(error => {
          console.error(error)
        })
    },
    addNewSentence(sentence, color) {
      const newItem = {
        id: Math.random(),
        type: "sentence",
        sentence: sentence,
        color: color,
        hidedot: false,
        letters: []
      };
      this.items.push(newItem);
      for (let i = 0; i < sentence.length; i++) {
        const char = sentence[i];
        setTimeout(() => {
          newItem.letters.push(char);
        }, (i + 1) * 50);
      }
    },
    getPrettyDate(d) {
      return moment.parseZone(d).format('DD MMM YYYY')
    },
    getTime(d) {
      return moment.parseZone(d).format('HH:mm')
    },
  }
}
</script>