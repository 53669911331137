<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-home-city-outline</v-icon> <span contenteditable="true">{{ this.room.Name }}</span></div>
            <v-spacer></v-spacer>
            <RoomDialog :roomAction="'update'" :roomDetails="room" />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <span class="font-weight-medium">Bed Number:</span> {{ room.BedNumber }}
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <span class="font-weight-medium">State:</span>
                <v-chip
                  v-if="room.State == 'Active'"
                  class="ma-1"
                  color="green"
                  text-color="white"
                >
                  {{ room.State }}
                </v-chip>
                <v-chip
                  v-if="room.State != 'Active'"
                  class="ma-1"
                  color="red"
                  text-color="white"
                >
                  {{ room.State }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import RoomDialog from './RoomDialog'

export default {
  data () {
    return {
      room: {},
      loading: false
    }
  },
  created () {
    this.get()
  },
  methods: {
    get () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/rooms/' + this.$route.params.id)
        //.get('http://localhost:13007/api/v1/rooms/' + id)
        .then((response) => {
          this.loading = false
          this.room = response.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting room'})
        })
    },
  },
  components: {
    RoomDialog
  }
}
</script>