<template>
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-card :loading="loading">
        <v-card-title>{{ mysqlConn }}</v-card-title>
        <v-card-text>MySQL Connections</v-card-text>
        <!--<v-card-text>
          <v-chip filter outlined  @click="testAPI()">
            Test API
          </v-chip>
        </v-card-text>-->
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12">
      <UsersTable />
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12">
      <CustomersTable />
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12">
      <LoggingView />
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12">
      <AppsTable />
    </v-col>
  </v-row>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

import AppsTable from './AppsTable'
import UsersTable from './UsersTable'
import LoggingView from './LoggingView'
import CustomersTable from './CustomersTable'

export default {

  data () {
    return {
      loading: false,
      mysqlConn: ''
    }
  },
  created () {
    this.getMySQLConn()
  },
  methods: {
    getMySQLConn: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/nmbot/db/mysql/connections')
        //.get('http://localhost:13007/api/v1/nmbot/db/mysql/connections')
        .then((r) => {
          this.loading = false
          this.mysqlConn = r.data[0].Value
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting MySQL connections'})
        })
    },
    testAPI() {
      this.loading = true
      httpClient
        .get('http://localhost:13007/api/v1/nmbot/tests')
        .then((r) => {
          this.loading = false
          this.mysqlConn = r.data[0].Value
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting MySQL connections'})
        })
    }
  },

  components: {
    AppsTable,
    UsersTable,
    LoggingView,
    CustomersTable,
  }
}
</script>
