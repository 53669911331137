<template>
  <div>
    <v-dialog v-model="dialog" max-width="80%">
      <template v-slot:activator="{ on }">
        <v-chip @click="get()"
          class="mx-1" color="green lighten-5" text-color="black"
          v-on="on">
          <v-avatar left
            class="green darken-4" style="color: white !important">
            <v-icon small>mdi-notebook</v-icon>
          </v-avatar>
          Notes
        </v-chip>
      </template>
      <v-card :loading="loading">
        <v-card-title>Notes</v-card-title>
        <v-card-subtitle>{{ sDetails.Name }}</v-card-subtitle>
        <v-card-text>
          <v-form>
            <v-textarea
              label="Notes"
              v-model="notes"
              rows="7" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    sDetails: {}
  },
  data: () => ({
    loading: false,
    dialog: false,
    notes: null,
    student: {},
  }),
  methods: {
    get() {
      httpClient
        .get('/customer/api/v1/student/' + this.sDetails.ID + '/notes')
        //.get('http://localhost:13001/api/v1/student/' + this.sDetails.ID + '/notes')
        .then((response) => {
          this.notes = response.data.Notes
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student notes'})
        })
    },
    save() {
      this.student.Notes = this.notes

      this.loading = true
      httpClient
        .put('/customer/api/v1/student/' + this.sDetails.ID + '/notes', this.student)
        //.put('http://localhost:13001/api/v1/student/' + this.sDetails.ID + '/notes', this.student)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Student notes successfully updated'})
          bus.$emit('refreshStudentView')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating student notes'})
        })
    }
  }
}
</script>