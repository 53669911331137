import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  pt: {
    message: {
      hello: 'Olá',
      today_events: 'Eventos de hoje'
    }
  },
  gb: {
    message: {
      hello: 'Hello',
      today_events: 'Today events'
    }
  },
  en: {
    message: {
      hello: 'Hello',
      today_events: 'Today events'
    }
  },
  fr: {
   message: {
      hello: 'Bonjour',
      todayetoday_eventsvents: "Leçons d'aujourd'hui"
    }
  },
  de: {
   message: {
      hello: 'Hallo',
      todayetoday_eventsvents: "Leçons d'aujourd'hui"
    }
  }
}

export default new VueI18n({
  locale: 'pt', // set locale
  fallbackLocale: 'pt', // set fallback locale
  messages: messages
})