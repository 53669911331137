<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-factory</v-icon> Suppliers <SupplierDialog />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.options="{ item }">
        <v-chip
            v-if="item.DeletedAt === null"
          class="mx-1"
          color="blue lighten-5"
          text-color="black"
          @click="openDialog(item)"
        >
          <v-avatar
            left
            class="blue darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-avatar>
          Editar
        </v-chip>
        <!--<v-btn v-if="item.DeletedAt == null" icon @click="confirmDelete(item)">
          <v-icon color="info">mdi-delete</v-icon>
        </v-btn>-->
        <!--<v-chip v-if="item.DeletedAt != null" small 
          class="ma-1" color="red" text-color="white"
        >
          DELETED
        </v-chip>-->
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import SupplierDialog from './SupplierDialog'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      columns: [
        { text: 'Name', align: 'left', value: 'Name' },
        { text: 'Email', align: 'left', value: 'Email' },
        { text: 'Phone', align: 'left', value: 'Phone' },
        { text: 'RateValue', align: 'left', value: 'RateValue' },
        { text: 'VatNumber', align: 'left', value: 'VatNumber' },
        { text: 'Options', sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50]
    }
  },
  created () {
    this.get()
    bus.$on('refreshSupplierList', () => this.get())
  },
  beforeDestroy () {
    bus.$off('refreshSupplierList', null)
  },
  methods: {
    get() {
      this.loading = true
      httpClient
        .get('/mix/api/v1/suppliers')
        //.get('http://localhost:13007/api/v1/suppliers')
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting suppliers'})
        })
    },
    openDialog(s) {
      bus.$emit('supplierDialog', {isNew: false, supplier: s})
    },
  },
  components: {
    SupplierDialog
  }
}
</script>
