<template>
  <v-card :loading="loadingLesson">
    <v-card-title>
      <v-icon left>mdi-calendar-range</v-icon> Lessons <StudentLessonDialog :studentID="customerID" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :server-items-length="totalRows"
      :options.sync="options"
      :sort-by="['DateTime']"
      :sort-desc="[true]"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.DateTime="{ item }">
        {{ formatDate(item.DateTime) }}
      </template>
      <template v-slot:item.Credits="{ item }">
        <v-btn icon @click="openFormPackageServicePopup(item.ID)">
          <v-icon color="info">mdi-plus</v-icon>
        </v-btn>
        <v-chip v-if="item.PackageName != null"
          class="ma-1" color="info" outlined
        >
          <v-icon left>mdi-package-variant</v-icon>
          {{ item.PackageName }}
        </v-chip>
        <v-chip v-if="item.ServiceName"
          class="ma-1" color="info" outlined
        >
          <v-icon left>mdi-room-service-outline</v-icon>
          {{ item.ServiceName }}
        </v-chip>
      </template>
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/lesson/' + item.ID">
          <v-chip
            color="blue lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="blue darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
            View
          </v-chip>
        </router-link>
        <v-chip
          
          @click="confirmDelete(item)"
          class="mx-1"
          color="red lighten-5"
          text-color="black"
        >
          <v-avatar
            left
            class="red darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-account-remove</v-icon>
          </v-avatar>
          Remove
        </v-chip>
      </template>
    </v-data-table>

    <FormPackageService />
    <StudentLessonDeleteConfirm />
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
import StudentLessonDialog from './StudentLessonDialog'
import StudentLessonDeleteConfirm from './StudentLessonDeleteConfirm'
import FormPackageService from '../PackageService/FormPackageService'

export default {
  props: {
    customerID: Number
  },
  data () {
    return {
      search: '',
      rows: [],
      columns: [
        { text: 'Start time', align: 'left', sortable: true, value: 'DateTime' },
        { text: 'Duration', align: 'left', sortable: true, value: 'Minutes' },
        { text: 'Credits', align: 'left', sortable: false, value: 'Credits' },
        { text: '', sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50],
      loadingLesson: false,
      totalRows: 0,
      options: {},
    }
  },
  created () {
    bus.$on('refreshStudentLessons', () => { this.getStudentLessons() })
    //this.getStudentLessons()
  },
  beforeDestroy () {
    bus.$off('refreshStudentLessons', null)
  },
  watch: {
    options: {
      handler () {
        this.getStudentLessons()
      },
      deep: true,
    },
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    openFormPackageServicePopup (lessonID) {
      bus.$emit('openFormPackageServiceDialog', {idLesson: lessonID, idStudent: this.customerID})
    },
    getStudentLessons: function () {
      this.loadingLesson = true
      httpClient
        .get('/event/api/v1/lessons/customer/' + this.customerID + '?' +
        //.get('http://localhost:13007/api/v1/lessons/customer/' + this.customerID + '?' +
          'pageNumber='+this.options.page+'&pageSize='+this.options.itemsPerPage+
          '&sortBy='+this.options.sortBy+'&sortDesc='+this.options.sortDesc)
        .then((resp) => {
          this.loadingLesson = false
          this.rows = []
          if (resp.data !== null) {
            this.rows = resp.data.events
            this.totalRows = resp.data.totalRows
          }
        })
        .catch(() => {
          this.loadingLesson = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student lessons'})
        })
    },
    confirmDelete: function (data) {
      bus.$emit('openStudentLessonDeleteConfirm', {lesson: data, customerID: this.customerID})
    },
  },
  components: {
    StudentLessonDeleteConfirm,
    StudentLessonDialog,
    FormPackageService
  }
}
</script>

<style>
</style>
