<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="5" md="4" lg="4" xl="4">
        <v-card :loading="loading" elevation="0">
          <!--<v-img
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
          ></v-img>-->

          <!--<v-card-title>{{ userDatabase }}</v-card-title>
          <v-card-subtitle>{{ userProfile }}</v-card-subtitle>-->

          <v-card-text>
            <!--<div class="subtitle-1 black--text">
              <v-icon>mdi-email</v-icon> {{ userEmail }}
            </div>-->
            <!--
            <v-row
              align="center"
              class="my-2 mx-0"
            >
              <v-rating
                :value="4.5"
                color="amber"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>

              <div class="grey--text ml-4">4.5 (413)</div>
            </v-row>
            -->
            <!--<div>..</div>-->
          </v-card-text>

        </v-card>
      </v-col>
      <v-col xs="12" sm="7" md="8" lg="8" xl="8">
        <v-card :loading="loadingNumbers" elevation="0">
          <v-card-title>Application numbers</v-card-title>
          <v-card-text>
            <v-chip-group
              active-class="primary white--text"
              column
            >
              <v-chip>{{appInfo.TotalLessons}} lesson(s)</v-chip>
              <v-chip>{{appInfo.TotalStudents}} student(s)</v-chip>
              <v-chip>{{appInfo.TotalStaffs}} staff(s) </v-chip>
              <!--<v-chip>Monthly income</v-chip>
              <v-chip>Yearly income</v-chip>-->
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col class="d-flex" cols="6" sm="2" md="2" lg="2">
        <v-select
          v-model="yearSelected"
          :items="yearList"
          item-text="name"
          item-value="id"
          label="Year"
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="6" sm="6" md="6" lg="4">
        <v-select
          v-model="monthSelected"
          :items="monthList"
          item-text="name"
          item-value="id"
          label="Month"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <MonthHighChart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <PackageChart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <ServiceChart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <PlaceChart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <CountryChart />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <StaffChart />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <SupplierChart />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import MonthHighChart from '../Charts/MonthHighChart'
import PackageChart from '../Charts/PackageChart'
import ServiceChart from '../Charts/ServiceChart'
import PlaceChart from '../Charts/PlaceChart'
import CountryChart from '../Charts/CountryChart'
import SupplierChart from '../Charts/SupplierChart'
import StaffChart from '../Charts/StaffChart'

export default {
  data: () => ({
    loading: false,
    loadingNumbers: false,
    userEmail: "",
    userDatabase: "",
    userProfile: "",
    appInfo: {},
    yearList: [],
    monthList: [],
    yearSelected: new Date().getFullYear(),
    monthSelected: 0,
  }),
  mounted () {
    if(this.$store.state.token != null) {
      this.userEmail = this.$store.state.userEmail
      this.userDatabase = this.$store.state.database
      this.userProfile = this.$store.state.profile
      this.getAll()
    }
    this.yearList = this.$store.state.yearList
    this.monthList = this.$store.state.monthList
  },
  watch: { 
    monthSelected() {
      bus.$emit("ChartsMonth", {"year": this.yearSelected, "month": this.monthSelected})
    },
    yearSelected() { 
      bus.$emit("ChartsYear", {"year": this.yearSelected, "month": this.monthSelected})
    }
  },
  methods: {
    getAll: function () {
      httpClient
        .get('/mix/api/v1/nmbot/apps/data/app')
        //.get('http://localhost:13007/api/v1/nmbot/apps/data/app')
        .then((resp) => {
          this.appInfo = resp.data
        })
        .catch(() => { 
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting apps and ports'})
        })
    },
  },
  components: {
    MonthHighChart,
    PackageChart,
    ServiceChart,
    PlaceChart,
    CountryChart,
    SupplierChart,
    StaffChart
  }
}
</script>