<template>
  <v-card class="my-2" :loading="loading">
    <v-card-title>
      <v-icon left>mdi-nas</v-icon> Equipment <ItemDialog :action="'create'" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :search="search"
        :items-per-page="10"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
        <template v-slot:item.Name="{ item }">
          <span @click="openDialog(item)">{{ item.Name }}</span>
        </template>
        <template v-slot:item.Quantity="{ item }">
          <span @click="openDialog(item)">{{ item.Quantity }}</span>
        </template>
        <template v-slot:item.Type="{ item }">
          <span @click="openDialog(item)">{{ item.Type }}</span>
        </template>
        <template v-slot:item.options="{ item }">
          <router-link v-bind:to="'/item/' + item.ID">
            <v-chip
              class="mx-1"
              color="blue lighten-5"
              text-color="black"
            >
              <v-avatar
                left
                class="blue darken-4"
                style="color: white !important"
              >
                <v-icon small>mdi-forward</v-icon>
              </v-avatar>
              View
            </v-chip>
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import ItemDialog from './ItemDialog'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      columns: [
      { text: 'Name', align: 'left', value: 'Name' },
      { text: 'Quantity', align: 'left', value: 'Quantity' },
      { text: 'Type', align: 'left', value: 'Type' },
      { text: 'Options', sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50]
    }
  },
  created: function () {
    bus.$on('refreshItems', () => { this.getAll() })
    this.getAll()
  },
  beforeDestroy () {
    bus.$off('refreshItems', null)
  },
  methods: {
    getAll: function () {
      this.loading = true
      httpClient
        .get('/item/api/v1/items')
        //.get('http://localhost:13007/api/v1/items')
        .then((r) => {
          this.loading = false
          if (r.data !== null) {
            this.rows = r.data
          } else {
            this.rows = []
          }
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting items'})
        })
    }
  },
  components: {
    ItemDialog
  }
}
</script>
