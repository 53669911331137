<template>
  <div>
    <v-toolbar>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="blue"
            icon
            v-on="on"
          ><v-icon>mdi-view-dashboard</v-icon></v-btn>
        </template>
        <v-date-picker v-model="date" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$refs.dialog.save(date);dayChange()">OK</v-btn>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
        </v-date-picker>
      </v-dialog>
      <v-toolbar-title>{{ styledDate }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <gb-flag code="pt" size="small" />
      <v-toolbar-items style="height: unset">
        <LessonDialog />
      </v-toolbar-items>
    </v-toolbar>
    <br />
    <v-card :loading="loadingDaily">
      <v-responsive>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <!-- https://alligator.io/vuejs/vue-google-maps/ -->
              <gmap-map
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false
                }"
                :center="center"
                :zoom="zoom"
                :map-type-id="mapType"
                style="width:100%; height: 350px;"
              > <!-- icon="https://vue.nelsonjvf.com/favicon.ico" -->
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  @click="openPanel(m)"
                ></gmap-marker>
              </gmap-map>

              <WorkViewFullDialog />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-card outlined>
                <v-card-title>Equipment to prepare</v-card-title>
                <v-card-text>
                  <div v-for="e in groupedDataBoardsWetsuits" :key="'Board' + e.groupKey" :id="'board'" style="margin: 0px">
                    <h2 style="padding: 15px 0; color: rgb(78, 113, 178)">{{ getHours(e.groupKey) }}</h2>

                    <h4>Boards</h4>
                    <ul style="list-style-type: none;">
                      <li v-for="(boardTotal, boardId) in e.boardTotals" :key="boardId">
                        <b>{{ boardTotal.total }}</b> unit(s) of <b>{{ boardTotal.name }}</b>
                      </li>
                    </ul>

                    <h4>Wetsuits</h4>
                    <ul style="list-style-type: none;">
                      <li v-for="(wetsuitTotals, wetsuitID) in e.wetsuitTotals" :key="wetsuitID">
                        <b>{{ wetsuitTotals.total }}</b> unit(s) of <b>{{ wetsuitTotals.name }}</b>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import LessonDialog from '../Lesson/LessonDialog'
import WorkViewFullDialog from '../Work/WorkViewFullDialog'

export default {
  data() {
    return {
      markers: [],
      lessons: [],
      groupedDataBoardsWetsuits: {}, 
      boardTotals: {},
      wetsuitTotals: {},
      modal: false,
      date: new Date().toISOString().substr(0, 10),
      styledDate: this.styleDate(),
      loadingDaily: false,
      center: { lat: 39.4922359, lng: -9.0507655 },
      zoom: 6,
      mapType: 'terrain',
    };
  },
  created () {
    this.getTodayLessons()
    bus.$on('refreshLessons', () => { this.getTodayLessons() })
    
    //this.center = { lat: 38.7356805, lng: -9.4400442 } // Surf Ville
    this.center = { lat: 38.73273550296528, lng: -9.288985545363454 }
    this.zoom = 11
    this.mapType = 'roadmap'

    if(this.$store.state.database === "cascaissurfschool") {
      this.center = { lat: 38.7256805, lng: -9.3400442 }
      this.zoom = 11
      this.mapType = 'roadmap'
    }
  },
  beforeDestroy () {
    bus.$off('refreshLessons', null)
  },
  methods: {
    styleDate() {
      if(this.isMobile()) {
        this.styledDate = moment.parseZone(this.date).format('DD-MM-YYYY')
        return
      }
      this.styledDate = moment.parseZone(this.date).format('dddd, D MMMM YYYY')
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      
      return false
    },
    isOldSmartSurf() {
      return (this.$store.state.database === 'cascaissurfschool' || this.$store.state.database === 'demosmartsurf')
    },
    openPanel: function () {
      bus.$emit('openEventFullDialog', {isNew: true})
    },
    dayChange: function () {
      this.getTodayLessons()
    },
    checkInProgress: function (from,duration) {
      var fDate, endDate, cDate
      cDate = new Date()
      fDate = Date.parse(from)
      endDate = moment.parseZone(fDate).add(duration, 'm').toDate()

      if((cDate <= endDate && cDate >= fDate)) {
        return true
      }
      return false
    },
    checkDone: function (from,duration) {
      var fDate, endDate, cDate
      fDate = Date.parse(from)
      endDate = moment.parseZone(fDate).add(duration, 'm').toDate()
      cDate = new Date()

      if(endDate < cDate) {
        return true
      }
      return false
    },
    getHours: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('H:mm')
    },
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    addMarker(lat, lng, id) {
      const marker = {
        lat: lat,
        lng: lng,
        id: id,
      };
      this.markers.push({ position: marker });
      // this.center = marker;
    },
    getTodayLessons: function() {
      this.loadingDaily = true
      this.styleDate()
      this.markers = []
      let day = moment.parseZone(this.date).format('YYYY-MM-DD')
      httpClient
        .get('/event/api/v1/lessons/students/day/' + day)
        //.get('http://localhost:13007/api/v1/lessons/students/day/' + day)
        .then((resp) => {
          this.loadingDaily = false
          if(resp.data !== null) {

            this.lessons = resp.data

            resp.data.forEach(e => {
              if(e.Latitude != null && e.Longitude != null) {
                if(e.Latitude == "0.000000" || e.Longitude == "0.000000") {
                  bus.$emit('snackBarMsg', {info: true, msg: 'To see this feature working correctly please set your places gps coords'})
                } else {
                  this.addMarker(+e.Latitude, +e.Longitude, e.ID)
                }
              } else {
                if(e.Place != null) {
                  bus.$emit('snackBarMsg', {info: true, msg: 'To see this feature working correctly please set your place ' + e.Place + ' gps coords'})
                }
              }

              e.TotalStudent = 0
              e.TotalStaff = 0
              if(e.Students != null) {
                e.TotalStudent = e.Students.length
              }
            })

            this.groupedDataBoardsWetsuits = this.lessons.reduce((groups, l) => {
              const groupKey = l.DateTime;

              if (!groups[groupKey]) {
                groups[groupKey] = {
                  groupKey,
                  boardTotals: {},
                  wetsuitTotals: {}
                }
              }

              if (l.Students !== undefined && Array.isArray(l.Students)) {
                l.Students.forEach(s => {
                  // Process IDBoard
                  if (s.IDBoard !== undefined && s.IDBoard !== null) {
                    if (!groups[groupKey].boardTotals[s.IDBoard]) {
                      groups[groupKey].boardTotals[s.IDBoard] = { total: 0, name: "" }
                    }
                    groups[groupKey].boardTotals[s.IDBoard].total++
                    groups[groupKey].boardTotals[s.IDBoard].name = s.Board
                  }

                  // Process IDWetsuit
                  if (s.IDWetsuit !== undefined && s.IDWetsuit !== null) {
                    if (!groups[groupKey].wetsuitTotals[s.IDWetsuit]) {
                      groups[groupKey].wetsuitTotals[s.IDWetsuit] = { total: 0, name: "" }
                    }
                    groups[groupKey].wetsuitTotals[s.IDWetsuit].total++
                    groups[groupKey].wetsuitTotals[s.IDWetsuit].name = s.Wetsuit
                  }
                })
              }

              return groups;
            }, {});

            console.log(this.groupedDataBoardsWetsuits);
          }
        })
        .catch((error) => {
          this.loadingDaily = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons - ' + error})
        })
    },
    /*getTodayWorks: function() {
      this.loadingDaily = true
      this.styledDate = moment.parseZone(this.date).format('dddd, D MMMM YYYY')
      this.markers = []
      let day = moment.parseZone(this.date).format('YYYY-MM-DD')
      httpClient
        .get('/work/api/v1/works/day/' + day)
        //.get('http://localhost:13007/api/v1/works/day/' + day)
        .then((resp) => {
          this.loadingDaily = false
          if(resp.data !== null) {

            this.works = resp.data

            resp.data.forEach(e => {
              if(e.Latitude != null && e.Longitude != null) {
                if(e.Latitude == "0.000000" || e.Longitude == "0.000000") {
                  //bus.$emit('snackBarMsg', {info: true, msg: 'To see this feature working correctly please set your places gps coords'})
                } else {
                  this.addMarker(+e.Latitude, +e.Longitude, e.ID)
                }
              }
            })
          }
        })
        .catch((error) => {
          this.loadingDaily = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting works - ' + error})
        })
    },*/
  },

  components: {
    LessonDialog,
    WorkViewFullDialog,
  }
};
</script>