<template>
  <div>
    <div>
      <highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>
    </div>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import { Chart } from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart
  },
  data () {
    return {
      yearList: this.$store.state.yearList,
      monthList: this.$store.state.monthList,
      yearSelected: new Date().getFullYear(),
      monthSelected: 0,
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          //verticalAlign: 'middle',
          //widthAdjust: -300,
          text: 'Supplier'
        },
        legend: {
          labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
        },
        series: [{
          innerSize: '50%',
          name: 'Sold',
          keys: ['name', 'y'],
          data: [],
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
        }]
      },
      updateArgs: [true, true, { duration: 1000 }]
    }
  },
  mounted () {
    this.get()
    document.getElementsByClassName('highcharts-credits')[0].remove()
  },
  created() {
    bus.$on("ChartsMonth", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
    })
    bus.$on("ChartsYear", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
    })
  },
  watch: {
    monthSelected() { this.get() },
    yearSelected() { this.get() }
  },
  methods: {
    get() {
      httpClient
        .get('mix/api/v1/reports/suppliers/' + this.yearSelected + '/' + this.monthSelected)
        //.get('http://localhost:13007/api/v1/reports/suppliers/' + this.yearSelected + '/' + this.monthSelected)
        .then((resp) => {
          this.chartOptions.series[0].data = []
          resp.data.forEach(e => {
            if (e.SupplierName !== undefined) {
              this.chartOptions.series[0].data.push([e.SupplierName, e.Total])
            }
          })
        })
        .catch(() => { 
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting suppliers information'})
        })
    }
  }
}
</script>