<template>
    <v-snackbar
        v-model="snackbar"
        :bottom="y === 'bottom'"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="timeout"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
        :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="closeSnackBar()">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { bus } from '../../main'

export default {
  data () {
    return {
      y: 'top',
      x: null,
      mode: '',
      timeout: -1,
      snackbar: false,
      snackColor: '',
      snackText: ''
    }
  },
  created () {
    bus.$on('snackBarMsg', (resp) => { this.showSnackBar(resp) })
    bus.$on('snackBarClose', () => { this.snackbar = false })
  },
  methods: {
    showSnackBar (resp) {
      this.snackbar = true
      this.snackText = resp.msg

      if (resp.error === true) {
        this.snackColor = 'red darken-1'
        this.timeout = 5000
      } else if (resp.info === true) {
        this.snackColor = 'blue darken-1'
        this.timeout = 5000
      } else {
        this.snackColor = 'green darken-1'
        this.timeout = 4000
      }
    },
    closeSnackBar() {
      this.snackbar = false
    }
  }
}
</script>