<template>
  <div>
    <v-dialog v-model="createDialog" max-width="500">
      <template v-slot:activator="{ on }">
        <v-chip
          class="mx-1"
          color="blue lighten-5"
          text-color="black"
          v-on="on"
        >
          <v-avatar
            left
            class="blue darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-avatar>
          {{ buttonLable }}
        </v-chip>
      </template>
      <v-card>
        <v-card-title>New customer</v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-text-field label="Name" :rules="nameRules" v-model="student.Name" autocomplete="nope"/>
            <v-text-field label="Email" :rules="emailRules" v-model="student.Email" autocomplete="nope"/>
            <v-text-field label="Phone number" :rules="phoneRules" v-model="student.Phone" autocomplete="nope"/>
            <v-autocomplete
              v-model="student.IDCountry"
              :items="countries"
              item-text="Name"
              item-value="ID"
              label="Country"
              placeholder="Select country.."
              autocomplete="nope"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="createStudent()">Create</v-btn>
          <v-btn text @click="createDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    buttonLable: String,
  },
  data: () => ({
    createDialog: false,
    exportDialog: false,
    student: {},
    countries: [],
    valid: false,
    nameRules: [
      v => !!v || 'Name is required'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phoneRules: [
      v => !!v || 'Phone is required',
      v => /\+?[0-9]+/.test(v) || 'Phone must be valid'
    ]
  }),
  watch: {
    createDialog () {
      if (this.createDialog) {
        this.getCountries()
      }
    },
  },
  methods: {
    getCountries() {
      httpClient
        .get('/mix/api/v1/countries')
        //.get('http://localhost:13008/api/v1/countries')
        .then((r) => {
          this.countries = r.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting countries'})
        })
    },
    createStudent: function () {
      httpClient
        .post('/customer/api/v1/students', this.student)
        //.post('http://localhost:13001/api/v1/students', this.student)
        .then((r) => {
          this.student = {}
          
          if(this.$route.name === "Lesson View") {
            bus.$emit('refreshLessonStudentDialogStudents', {student: r.data})
          }

          if(this.$route.name === "List Student") {
            this.$router.push({ path: 'student/' + r.data.ID })
          }

          this.createDialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Student successfully created'})
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating student'})
        })
    }
  }
}
</script>