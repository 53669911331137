<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ on }">
      <v-chip
        class="ma-1"
        color="blue lighten-5"
        text-color="black"
        v-on="on"
      >
        <v-avatar left
          class="blue darken-4"
          style="color: white !important"
        >
          <v-icon small>{{ btnIcon }}</v-icon>
        </v-avatar>
        <span>{{ btnText }}</span>
      </v-chip>
    </template>
    <v-card class="mx-auto" :loading="loading">
      <v-card-title><span v-if="isNew">Criar equipamento</span><span v-if="!isNew">Alterar equipamento</span></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-3">
        <v-text-field
          v-model="e.Brand"
          label="Marca"
          required
        ></v-text-field>
        <v-text-field
          v-model="e.Model"
          label="Modelo"
          required
        ></v-text-field>
        <v-text-field
          v-model="e.PlateNumber"
          label="Matriícula"
          required
        ></v-text-field>
        <v-text-field
          v-model="e.PlateDate"
          label="Data da Matrícula"
          required
        ></v-text-field>
        <v-text-field
          v-model="e.SerialNumber"
          label="Número de Série"
          required
        ></v-text-field>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
            <v-autocomplete
              v-model="e.IDType"
              :items="types"
              item-text="Name"
              item-value="ID"
              label="Tipo de equipamento"
              placeholder="Selecione o tipo de equipamento"
              prepend-icon="mdi-nas"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
            <v-text-field
              v-model="e.Notes"
              label="Notas"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="create()" v-if="isNew">Criar</v-btn>
        <v-btn color="blue darken-1" text @click="update()" v-if="!isNew">Guardar</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    action: String,
    details: {}
  },
  data () {
    return {
      isNew: true,
      dialog: false,
      loading: false,
      e: {},
      types: [],
    }
  },
  computed: {
    btnIcon: function () {
      if (this.action === 'update') { return 'mdi-pencil' } else { return 'mdi-plus' }
    },
    btnText: function () {
      if (this.action === 'update') { return 'Alterar' } else { return 'Criar' }
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        if(this.action === 'update') {
          this.isNew = false
          this.e = this.details
        }
      }
    },
  },
  created () {
    this.getTypes()
  },
  methods: {
    getTypes: function () {
      this.loading = true
      httpClient
        .get('/equipment/api/v1/equipments/types')
        //.get('http://localhost:13011/api/v1/equipments/types')
        .then((r) => {
          this.loading = false
          this.types = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao carregar perfis do trabalhdores'})
        })
    },
    create: function () {
      this.loading = true
      httpClient
        .post('/equipment/api/v1/equipments', this.e)
        //.post('http://localhost:13011/api/v1/equipments', this.e)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Equipamento criado'})
          bus.$emit('refreshEquipments')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao criar equipamento'})
        })
    },
    update: function () {
      this.loading = true
      httpClient
        .put('/equipment/api/v1/equipment/' + this.e.ID, this.e)
        //.put('http://localhost:13011/api/v1/equipment/' + this.e.ID, this.e)
        .then(() => {
          this.loading = false
          bus.$emit('refreshEquipment')
          bus.$emit('snackBarMsg', {error: false, msg: 'Equipamento atualizado'})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao atualizar equipamento'})
        })
    }
  }
}
</script>
