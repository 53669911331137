<template>
  <div style="min-width: 310px">
    <highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import { Chart } from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart
  },
  data () {
    return {
      yearList: this.$store.state.yearList,
      monthList: this.$store.state.monthList,
      yearSelected: new Date().getFullYear(),
      monthSelected: 0,
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: ""
        },
        color: ['#00ACC1', '#7CB342'],
        xAxis: {
          title: {
            text: 'Days'
          },
          gridLineWidth: 0,
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          },
        },
        tooltip: {
          backgroundColor: '#FCFFC5',
          borderColor: 'black',
          borderRadius: 10,
          borderWidth: 3,
          formatter: function() {
            if(this.series.name == 'Events') {
              return '<b>' + this.y + '</b> events happened in <b>' + this.x + '</b>'
            } else {
              return '<b>' + this.y + '</b> customer attempted to events in <b>' + this.x + '</b>'
            }
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          },
          bar: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          }
        },
        responsive: {  
          rules: [{  
            condition: {  
              maxWidth: 500  
            },  
            chartOptions: {  
              legend: {  
                enabled: false  
              }  
            }  
          }]  
        },
        series: []
      },
      updateArgs: [true, true, { duration: 1000 }]
    }
  },
  mounted () {
    this.get()
    document.getElementsByClassName('highcharts-credits')[0].remove()
  },
  created() {
    bus.$on("ChartsMonth", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
      this.get()
    })
    bus.$on("ChartsYear", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
      this.get()
    })
  },
  watch: {
    monthSelected() { this.get() },
    yearSelected() { this.get() }
  },
  methods: {
    get() {
      httpClient
        .get('mix/api/v1/reports/monthly/' + this.yearSelected + '/' + this.monthSelected)
        //.get('http://localhost:13007/api/v1/reports/monthly/' + this.yearSelected + '/' + this.monthSelected)
        .then((resp) => {
          let days = []
          let customers = {
            name: 'Customers',
            type: 'line',
            data: []
          }
          let events = {
            name: 'Events',
            type: 'bar',
            data: []
          }

          resp.data.forEach(e => {
            events.data.push(e.TotalEvents)
            customers.data.push(e.TotalCustomers)
            days.push(e.Day)
          })

          this.chartOptions.series = []

          this.chartOptions.series.push(events)
          this.chartOptions.series.push(customers)
          if(this.monthSelected === 0) {
            this.chartOptions.xAxis.categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          } else {
            this.chartOptions.xAxis.categories = days
          }
        })
        .catch(() => { 
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson and students information'})
        })
    }
  }
}
</script>