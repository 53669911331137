<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ on }">
      <v-chip
        class="ma-1"
        color="blue lighten-5"
        text-color="black"
        v-on="on"
      >
        <v-avatar left
          class="blue darken-4"
          style="color: white !important"
        >
          <v-icon small>{{ btnIcon }}</v-icon>
        </v-avatar>
        <span>{{ btnText }}</span>
      </v-chip>
    </template>
    <v-card class="mx-auto" :loading="loading">
      <v-card-title><span v-if="isNew">Create equipment</span><span v-if="!isNew">Update equipment</span></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-3">
        <v-text-field
          v-model="item.Name"
          label="Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="item.Quantity"
          label="Quantity"
          type="number"
          required
        ></v-text-field>
        <v-autocomplete
            prepend-icon="mdi-alpha-t-circle-outline"
            v-model="itemIdType"
            :items="item_types"
            item-text="name"
            item-value="id"
            label="Type"
            placeholder="Select type.."
          ></v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="create()" v-if="isNew">Create</v-btn>
        <v-btn color="info" text @click="update()" v-if="!isNew">Save</v-btn>
        <v-btn text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    action: String,
    details: {}
  },
  data () {
    return {
      isNew: true,
      dialog: false,
      loading: false,
      item: {},
      item_types: [],
      itemIdType: 0,
    }
  },
  computed: {
    btnIcon: function () {
      if (this.action === 'update') { return 'mdi-pencil' } else { return 'mdi-plus' }
    },
    btnText: function () {
      if (this.action === 'update') { return 'Edit' } else { return 'Create' }
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        this.getItemTypes()
        if(this.action === 'update') {
          this.isNew = false
          this.item = this.details
          this.itemIdType = this.item.IDType
          console.log(this.item)
          console.log(this.itemIdType)
        }
      }
    },
  },
  methods: {
    getItemTypes: function () {
      httpClient
        .get('/item/api/v1/items/types')
        .then((response) => {
          this.item_types = response.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting item types'})
        })
    },
    create: function () {
      this.loading = true
      this.item.IDType = this.itemIdType.toString()
      httpClient
        .post('/item/api/v1/items', this.item)
        //.post('http://localhost:13007/api/v1/items', this.item)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Item created'})
          bus.$emit('refreshItems')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating item'})
        })
    },
    update: function () {
      this.loading = true
      this.item.IDType = this.itemIdType.toString()
      httpClient
        .put('/item/api/v1/item/' + this.item.ID, this.item)
        //.put('http://localhost:13007/api/v1/item/' + this.item.ID, this.item)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Item updated'})
          bus.$emit('refreshItemView', {lesson: this.lesson})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating item'})
        })
    }
  }
}
</script>
