<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card class="mx-0" :loading="loadingLesson" elevation="1">
          <v-card-title>
            <!-- Weather refactoring needed -->
            <v-row v-if="curTemp != null">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-row>
                  <v-col cols="12" sm="4" md="4" lg="4" v-if="curTemp">
                    <v-badge right color="grey" v-if="curTemp">
                      <template v-slot:badge>
                        <span>{{ curTemp }}</span>
                      </template>
                      <v-icon large color="green lighten-1">mdi-temperature-celsius</v-icon>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" v-if="curHumidity">
                    <v-badge right color="grey lighten-1" v-if="curHumidity">
                      <template v-slot:badge>
                        <span>{{ curHumidity }}</span>
                      </template>
                      <v-icon large color="blue lighten-1">mdi-water-percent</v-icon>
                    </v-badge>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" v-if="curWindSpeed">
                    <v-badge right color="grey" v-if="curWindSpeed">
                      <template v-slot:badge>
                        <span>{{ curWindSpeed }}</span>
                      </template>
                      <v-icon large color="blue-grey lighten-1">mdi-weather-windy</v-icon>
                    </v-badge>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <span v-if="lesson.Place">{{ lesson.Place }}</span>
            <span v-if="!lesson.Place" class="font-italic font-weight-thin" style="color: #ccc; ">Place not defined</span>
            <v-spacer></v-spacer>
            <v-chip v-if="this.$store.state.profile === 'Administrator'" class="s-chip" color="blue lighten-5" @click="updateLesson()">
              <v-avatar class="blue darken-4" style="color: white !important">
                <v-icon small>mdi-pencil</v-icon>
              </v-avatar>
            </v-chip>
            <LessonDialog v-if="!isCalendarView()" />
            <LessonCopyDialog />
            <v-chip v-if="this.$store.state.profile === 'Administrator'" class="s-chip" color="orange lighten-5" text-color="black" @click="copyEvent()">
              <v-avatar class="orange darken-4" style="color: white !important">
                <v-icon small>mdi-content-copy</v-icon>
              </v-avatar>
            </v-chip>
            <LessonDeleteConfirm />
            <v-chip v-if="this.$store.state.profile === 'Administrator'" class="s-chip" color="red lighten-5" text-color="black" @click="confirmDelete()">
              <v-avatar class="red darken-4" style="color: white !important">
                <v-icon small>mdi-delete</v-icon>
              </v-avatar>
            </v-chip>
          </v-card-title>
          <v-card-subtitle class="font-weight-regular grey--text">{{ styledDate }}</v-card-subtitle>
          <v-card-text>
            <v-chip class="ma-1" color="info" outlined>
              <v-icon left>mdi-alarm-check</v-icon>
              <span >{{ lesson.Minutes }} min.</span>
            </v-chip>
            <v-chip v-if="lesson.Type" class="ma-1" color="info" outlined>
              <v-icon left>mdi-brightness-5</v-icon>
              <span>{{ lesson.Type }}</span>
            </v-chip>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="this.lessonEquipment">
                <span class="font-weight-black">Boards</span>
                <p v-for="(e, k) in lessonEquipment"
                  :key="'Board' + k"
                  :id="'board'"
                  style="margin: 0px">
                  <span v-if="e.Type === 'Board'">{{ e.Total }} - {{ e.Name }}</span>
                </p>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" v-if="this.lessonEquipment">
                <span class="font-weight-black">Wetsuits</span>
                <p v-for="(e, k) in lessonEquipment"
                  :key="'Wetsuit' + k"
                  style="margin: 0px">
                  <span v-if="e.Type === 'Wetsuit'">{{ e.Total }} - {{ e.Name }}</span>
                </p>
              </v-col>
            </v-row> 
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" v-if="this.lessonEquipment">
                <p class="font-weight-black" style="margin: 0px">Transport</p>
                <span v-for="i in lessonTransport"
                  :key="'Name' + i.Name"
                  class="ma-0">
                  <v-chip
                    class="ma-1"
                    color="info"
                    outlined
                  >
                    {{ i.Total }} - {{ i.Name }}
                  </v-chip>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card class="mx-0" :loading="loadingComments" elevation="1">
          <v-card-title>
            Comments
            <LessonCommentsDialog :lessonDetails="lesson" />
          </v-card-title>
          <v-card-text>
            <pre class="font-italic" style="white-space: pre-wrap" v-html="formattedComment"></pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card elevation="1">
          <LessonStudent :lessonID="+this.lesson.ID" v-if="loadStudentsAndStaffs" :key="this.lesson.ID" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card elevation="1">
          <LessonStaff :lessonID="+this.lesson.ID" v-if="loadStudentsAndStaffs" :key="this.lesson.ID" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
import LessonStudent from './LessonStudent'
import LessonStaff from './LessonStaff'
import LessonCopyDialog from './LessonCopyDialog'
import LessonDialog from './LessonDialog'
import LessonDeleteConfirm from './LessonDeleteConfirm'
import LessonCommentsDialog from './LessonCommentsDialog'

export default {
  props: {
    propLessonID: Number
  },
  data () {
    return {
      styledDate: null,
      error: '',
      lesson: {},
      lessonComments: {},
      lessonEquipment: {},
      lessonTransport: {},
      tempDesc: null,
      curTemp: null,
      curTempMax: null,
      curTempMin: null,
      curWindSpeed: null,
      curWindDeg: null,
      curHumidity: null,
      curPressure: null,
      loadingLesson: false,
      loadingComments: false,
      loadStudentsAndStaffs: false
    }
  },
  created () {
    if(this.$route.params.id !== null && typeof this.$route.params.id !== "undefined") {
      this.lesson.ID = this.$route.params.id
      this.load()
    }

    // Fullscreen popup use-case - First click ever
    if(this.propLessonID !== undefined) {
      this.lesson.ID = this.propLessonID
      this.load()
    }

    bus.$on('refreshLessonDetails', () => this.getLesson())
    bus.$on('refreshLessonComments', () => this.getLessonComments())
    bus.$on('refreshLessonItems', () => this.getLessonItems())
    bus.$on('refreshLessonTransports', () => this.getLessonTransports())
    bus.$on('loadLesson', (lessonID) => {
      this.lesson.ID = lessonID
      this.load()
      bus.$emit('refreshLessonStudentList')
    })
  },
  beforeDestroy () {
    bus.$off('refreshLessonDetails', null)
    bus.$off('refreshLessonComments', null)
    bus.$off('refreshLessonItems', null)
    bus.$off('refreshLessonTransports', null)
  },
  computed: {
    formattedComment() {
      return this.formatPhoneNumbers(this.lessonComments.Comment);
    },
  },
  methods: {
    formatPhoneNumbers(comment) {
      if (comment === undefined) {
        return 
      }

      const phoneRegex = /(?:\+\d{1,2}\s?)?(?:\(\d+\)\s?)?\d{5,}/g;
      var formattedComment = comment.replace(phoneRegex, (match) => {
        const phoneNumber = match.replace(/\s|-/g, '');
        return `<a href="tel:${phoneNumber}">${match}</a>`;
      });

      const payRegex = /^.*\bPAY\b.*$/gm;
      formattedComment = formattedComment.replace(payRegex, (match) => {
        return `<span style="color:red">${match}</span>`;
      });

      const paidRegex = /^.*\PAID\b.*$/gm;
      formattedComment = formattedComment.replace(paidRegex, (match) => {
        return `<span style="color:green">${match}</span>`;
      });

      return formattedComment;
    },
    isCalendarView() {
      return this.$route.name === "Calendar"
    },
    load () {
      this.getLesson()
      this.getLessonItems()
      this.getLessonTransports()
      this.getLessonComments()
    },
    copyEvent () {
      /* Emit to CopyEvent component */
      bus.$emit('openCopyEvent', {
        lesson: this.lesson,
        lessonComments: this.lessonComments,
        lessonEquipment: this.lessonEquipment,
      })
    },
    getWeather () {
      if(this.lesson.Place) {
        axios
          .get('https://api.openweathermap.org/data/2.5/weather?q=' + this.lesson.Place + '&APPID=ed39f08964de0b5ffc4d5fb9841c94b2&units=metric', {
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then((resp) => {
            this.tempDesc = resp.data.weather[0].main

            this.curTemp = Math.round(resp.data.main.temp)
            this.curTempMax = resp.data.main.temp_max
            this.curTempMin = resp.data.main.temp_min

            this.curWindSpeed = resp.data.wind.speed
            this.curWindDeg = resp.data.wind.deg

            this.curHumidity = resp.data.main.humidity
            this.curPressure = resp.data.main.pressure
          })
          .catch(() => {    
            this.curTemp = null
            this.curWindSpeed = null
            this.curHumidity = null
          })
      } else {
        this.curTemp = null
        this.curWindSpeed = null
        this.curHumidity = null
      }
    },
    getLesson () {
      this.loadingLesson = true
      httpClient
        .get('/event/api/v1/lesson/' + this.lesson.ID)
        //.get('http://localhost:13007/api/v1/lesson/' + lesson.ID)
        .then((r) => {
          this.loadingLesson = false
          this.lesson = r.data
          this.loadStudentsAndStaffs = true
          this.styledDate = moment.parseZone(this.lesson.DateTime).format('dddd, D MMM YYYY - HH:mm')
          //this.getWeather()
        })
        .catch(() => {
          this.loadingLesson = false
          bus.$emit('closeLessonDialogFullScreen')
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson'})
        })
    },
    updateLesson() {
      bus.$emit("openLessonDialogPassDate", {lesson: this.lesson})
    },
    getLessonItems () {
      this.loadingLesson = true
      httpClient
        .get('/mix/api/v1/items/lesson/' + this.lesson.ID)
        //.get('http://localhost:13007/api/v1/items/lesson/' + this.lesson.ID)
        .then((response) => {
          this.loadingLesson = false
          this.lessonEquipment = response.data
        })
        .catch(() => {
          this.loadingLesson = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson equipment'})
        })
    },
    getLessonTransports () {
      this.loadingLesson = true
      httpClient
        .get('/mix/api/v1/transports/lesson/' + this.lesson.ID)
        //.get('http://localhost:13007/api/v1/transports/lesson/' + this.lesson.ID)
        .then((r) => {
          this.loadingLesson = false
          this.lessonTransport = r.data
        })
        .catch(() => {
          this.loadingLesson = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson equipment'})
        })
    },
    getLessonComments () {
      this.loadingComments = true
      httpClient
        .get('/event/api/v1/comment/lesson/' + this.lesson.ID)
        //.get('http://localhost:13007/api/v1/comment/lesson/' + this.lesson.ID)
        .then((response) => {
          this.loadingComments = false
          this.lessonComments = response.data
        })
        .catch(() => {
          this.loadingComments = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson comments'})
        })
    },
    confirmDelete: function () {
      bus.$emit('openLessonDeleteConfirm', {lesson: this.lesson})
    }
  },
  components: {
    LessonDialog,
    LessonCommentsDialog,
    LessonCopyDialog,
    LessonStudent,
    LessonStaff,
    LessonDeleteConfirm,
  }
}
</script>