<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="mx-auto" :loading="loading">
      <v-card-title><span v-if="isNew">Create event</span><span v-if="!isNew">Update event</span></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
            <v-autocomplete
              v-model="event.IDPlace"
              :items="places"
              item-text="Name"
              item-value="ID"
              label="Place"
              placeholder="Select place.."
              prepend-icon="mdi-map-marker"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6" class="nm-NoPaddingTopBot">
            <v-menu v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" @input="menuDate = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="nm-NoPaddingTopBot">
            <v-menu
              ref="menu"
              v-model="menuTime"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="time"
                  label="Time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuTime"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" class="nm-NoPaddingTopBot">
            <v-text-field
              prepend-icon="mdi-timer"
              label="Duration"
              class="purple-input"
              type="number"
              v-model.number="minutes"/>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" class="nm-NoPaddingTopBot">
            <v-autocomplete
              prepend-icon="mdi-alpha-t-circle-outline"
              v-model="event.IDType"
              :items="types"
              item-text="Name"
              item-value="ID"
              label="Type"
              placeholder="Select type.."
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" :disabled="!valid" text v-if="isNew" @click="create()">Create</v-btn>
        <v-btn color="info" :disabled="!valid" text v-if="!isNew" @click="save()">Update</v-btn>
        <v-btn text @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      isNew: true,
      event: {},
      date: new Date().toISOString().substr(0, 10),
      error: '',
      dialog: false,
      places: [],
      types: [],
      menuDate: false,
      time: null,
      menuTime: false,
      minutes: null,
      timeRules: [
        v => !!v || 'Time is required',
      ],
      minutesRules: [
        v => v !== null && v !== undefined && v !== '' || 'Minutes is required',
        v => /[0-9]+/.test(v) || 'Minutes must be valid'
      ],
      valid: false
    }
  },
  watch: {
    minutes: {
      handler() {
        this.validate();
      }
    }
  },
  created() {
    this.validate()
    bus.$on('createFromCalendar', (e) => {
      this.isNew = true
      this.event.ID = null
      this.minutes = null
      this.date = e.date
      this.time = e.time
      this.dialog = true
      this.getPlaces()
      this.getLessonTypes()
      this.validate()
    })
    bus.$on('openLessonDialogPassDate', (e) => {
      if(e.lesson !== undefined) {
        let dateSplit = e.lesson.DateTime.split("T")
        let dateTime = dateSplit[1].split(":")

        this.isNew = false
        this.event = e.lesson
        this.minutes = e.lesson.Minutes
        this.date = dateSplit[0]
        this.time = dateTime[0]+":"+dateTime[1]
      } else {
        this.isNew = true
        this.lesson = { Minutes: 60 }
        this.date = e.date
        this.time = e.time
      }
      this.dialog = true
      this.getPlaces()
      this.getLessonTypes()
    })
    bus.$on('openLessonCreateEventDialog', () => {
      this.dialog = true
      this.getPlaces()
      this.getLessonTypes()
    })
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    getPlaces () {
      httpClient
        .get('/place/api/v1/places')
        .then((response) => {
          this.places = response.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting places'})
        })
    },
    getLessonTypes () {
      httpClient
        .get('/event/api/v1/lessontypes')
        .then((response) => {
          this.types = response.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson types'})
        })
    },
    validate() {
      const minutesValid = this.minutesRules.every(rule => rule(this.minutes) === true)
      const timeValid = this.timeRules.every(rule => rule(this.time) === true)

      this.valid = minutesValid && timeValid
    },
    create () {
      this.event.DateTime = this.date + "T" + this.time + ":00Z"
      this.event.Minutes = this.minutes
      this.event.IDPlace = this.event.IDPlace
      this.event.IDType = this.event.IDType

      let curDate = new Date().toISOString().substr(0, 19)

      this.event.CreationDate = curDate + "Z"
      this.event.LastModifyDate = curDate + "Z"

      httpClient
        .post('/event/api/v1/lessons', this.event)
        //.post('http://localhost:13007/api/v1/lessons', this.event)
        .then((r) => {
          this.dialog = false
          if(this.$route.name === "Calendar") {
            if (this.event.IDPlace !== undefined) {
              const place = this.places.find((p) => p.ID === this.event.IDPlace)
              if (place !== undefined) {
                this.event.Place = place.Name
              }
            }

            if (this.event.IDType !== undefined) {
              const type = this.types.find((p) => p.ID === this.event.IDType)
              if (type !== undefined) {
                this.event.Type = type.Name
              }
            }

            this.event.ID = r.data.ID

            bus.$emit('refreshCalendarLessons', {lesson: this.event})
          } else {
            this.$router.push({ path: 'lesson/' + r.data.ID })
          }
          this.event = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Event successfully created'})
        })
        .catch((e) => {
          console.log("error", e)
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating lesson'})
        })
    },
    save () {
      this.event.Minutes = this.minutes
      this.event.DateTime = this.date + "T" + this.time + ":00Z"
      this.event.LastModifyDate = new Date().toISOString()
      this.loading = true
      this.saveLesson()
    },
    saveLesson () {
      httpClient
        .put('/event/api/v1/lessons/' + this.event.ID, this.event)
        //.put('http://localhost:13007/api/v1/lessons/' + this.event.ID, this.event)
        .then(() => {
          bus.$emit('snackBarMsg', {error: false, msg: 'Lesson successfully updated'})
          bus.$emit('refreshLessonDetails')     
          if(this.$route.name === "Calendar") {
            bus.$emit("refreshCalendarLessons", null)
          } else {
            bus.$emit('refreshLessons', true)
          }
          this.loading = false
          this.dialog = false
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating lesson'})
        })
    }
  }
}
</script>