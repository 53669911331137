<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title>
      <v-icon left>mdi-briefcase-account</v-icon> Staffs
      <LessonStaffDialog v-if="userProfile === 'Administrator'" :lessonID="this.lessonID" :addLesson="true" />
      <v-tooltip bottom v-if="!showTable">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="showTable = !showTable">mdi-view-list</v-icon>
        </template>
        <span>View cards</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showTable">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="showTable = !showTable">mdi-view-module</v-icon>
        </template>
        <span>View list</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-card-text v-if="!showTable">
      <div class="layout row" style="flex-wrap: wrap !important;">
        <div class="flex" v-for="i in filteredRows" :key="i.ID">
      <!-- BUG WITH PRODUCTION BUILD - the style .layout is loaded after .row witch replaces the flex-wrap with the value "nowrap" -->
      <!--<v-layout row>
        <v-flex wrap v-for="i in filteredRows" :key="i.ID">-->
          <v-card class="mx-1 my-2" outlined>
            <v-card-text>
              <p>{{ i.Name }}
                <v-chip v-if="userProfile === 'Administrator'" class="mx-1" color="info" outlined>
                  <v-icon left>mdi-currency-eur</v-icon>
                  {{ i.Payment }}
                </v-chip>
              </p>
              <div>
                <router-link v-bind:to="'/staff/' + i.IDStaff">
                  <v-chip color="blue lighten-5" text-color="black">
                    <v-avatar class="blue darken-4" style="color: white !important">
                      <v-icon small>mdi-forward</v-icon>
                    </v-avatar>
                  </v-chip>
                </router-link>
                <v-chip v-if="userProfile === 'Administrator'"
                  @click="remove(i.IDStaff)"
                  class="mx-1" color="red lighten-5" text-color="black">
                  <v-avatar class="red darken-4" style="color: white !important">
                    <v-icon small>mdi-account-remove</v-icon>
                  </v-avatar>
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        <!--</v-flex>
      </v-layout>-->
        </div>
      </div>
    </v-card-text>
    <v-data-table
      v-if="showTable"
      :headers="filteredColumns"
      :items="rows"
      :search="search"
      :items-per-page="5"
      :sort-by="['Name']"
      :sort-desc="[true]"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.Name="{ item }">
        {{ item.Name }} {{ item.Surname }}
      </template>
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/staff/' + item.IDStaff">
          <v-chip class="s-chip" color="blue lighten-5">
            <v-avatar class="blue darken-4" style="color: white !important">
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
          </v-chip>
        </router-link>
        <v-chip v-if="userProfile === 'Administrator'" 
          @click="remove(item.IDStaff)" class="s-chip" color="red lighten-5">
          <v-avatar class="red darken-4" style="color: white !important">
            <v-icon small>mdi-account-remove</v-icon>
          </v-avatar>
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import LessonStaffDialog from './LessonStaffDialog'

export default {
  props: {
    lessonID: Number
  },
  data () {
    return {
      userProfile: "",
      loading: false,
      search: '',
      rows: [],
      filteredRows: [],
      rowsPerPageItems: [5, 10, 25, 50],
      columns: [
        { text: 'Name', align: 'left', sortable: true, value: 'Name' },
        { text: 'Payment', align: 'left', sortable: true, value: 'Payment' },
        { text: 'Options', sortable: false, value: 'options' }
      ],
      showTable: true,
      hover: false,
    }
  },
  computed: {
    filteredColumns() {
      const profile = this.$store.state.profile
      return this.columns.filter((column) => {
        if (profile === 'Administrator') {
          return true;
        } else {
          return column.value !== 'Payment';
        }
      });
    },
  },
  watch: {
    search: function (val) {
      this.filteredRows = this.rows.filter(function (a) {
        return a.Name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
    },
  },
  created () {
    this.userProfile = this.$store.state.profile
    bus.$on('refreshLessonStaffs', () => { this.getLessonStaffs() })
    this.getLessonStaffs()
  },
  beforeDestroy () {
    bus.$off('refreshLessonStaffs', null)
  },
  methods: {
    getLessonStaffs () {
      this.loading = true
      httpClient
        .get('/event/api/v1/lessonstaff/' + this.lessonID)
        //.get('http://localhost:13007/api/v1/lessonstaff/' + this.lessonID)
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
          this.filteredRows = this.rows
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting staffs lesson'})
        })
    },
    remove (idStaff) {
      this.loading = true
      httpClient
        .delete('/event/api/v1/lessonstaff/' + this.lessonID + '/' + idStaff)
        //.delete('http://localhost:13007/api/v1/lessonstaff/' + this.lessonID + '/' + idStaff)
        .then(() => {
          this.loading = false
          this.getLessonStaffs()
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error deleting work staffs'})
        })
    }
  },
  components: {
    LessonStaffDialog
  }
}
</script>

<style>
</style>
