<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="600px">
      <v-card :loading="loading">
        <v-card-title v-if="pckInfo.Name">{{ pckInfo.Name }}
          <v-spacer />
          <v-chip
            v-if="!pckInfo.PaymentFlag"
            color="red lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="red darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-currency-usd-off</v-icon>
            </v-avatar>
            Not Paid
          </v-chip>
          <v-chip
            v-if="pckInfo.PaymentFlag"
            color="green lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="green darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-currency-usd</v-icon>
            </v-avatar>
            Paid
          </v-chip>
        </v-card-title>
        <v-card-text v-if="pckInfo.Lessons">
          <div v-if="pckInfo.CreationDate"><b>Created at:</b> {{ formatDate(pckInfo.CreationDate) }}</div>
          <div v-if="pckInfo.LessonPrice"><b>Lesson Price -</b> {{ pckInfo.LessonPrice }} €</div>
          <div v-if="pckInfo.LessonNumber"><b>Lesson Number -</b> {{ +pckInfo.LessonNumber }} units</div>
          <div v-if="showLessons">
            <div class="font-weight-bold my-2">Lessons list</div>
            <div v-for="l in pckInfo.Lessons" :key="l.ID" class="mx-2 my-0" elevation="0">
              <span v-if="l.Type" class="font-weight-bold">{{ l.Type }}</span> <span v-if="l.Type">lesson</span><span v-if="!l.Type">Lesson</span> at <span class="font-weight-bold">{{ l.Place }}</span> started at <span class="font-weight-bold">{{ formatDate(l.DateTime) }}</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-chip
            class="ma-1"
            color="yellow lighten-4"
            text-color="black"
            @click="showLessons = !showLessons"
            v-if="pckInfo.Lessons && pckInfo.Lessons.length"
          >
            <v-avatar
              left
              class="yellow darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-calendar-range</v-icon>
            </v-avatar>
            <span v-if="!showLessons">Show lessons</span><span v-if="showLessons">Hide lessons</span>
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDelete()">Delete</v-btn>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      <!-- <CustomerPackageDeleteConfirm /> -->
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
//import CustomerPackageDeleteConfirm from './CustomerPackageDeleteConfirm'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    showLessons: false,
    pck: {},
    pckInfo: {},
  }),
  mounted: function () {
    bus.$on('openStudentPackageDetails', (data) => {
      this.showLessons = false
      this.pckInfo = {}
      this.dialog = true
      this.pck = data.pck
      this.getStudentsPackageLessons()
    })
  },
  beforeDestroy () {
    bus.$off('openStudentPackageDetails', null)
  },
  methods: {
    formatDate(dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    getStudentsPackageLessons: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/packages/lessons/' + this.pck.IDStudentPackage)
        //.get('http://localhost:13004/api/v1/packages/lessons/' + this.pck.IDStudentPackage)
        .then((resp) => {
          this.loading = false
          if(resp) {
            this.pckInfo = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting package details'})
        })
    },
    confirmDelete() {
      if(confirm("Are you sure that you want to remove this package from student?")) {
        this.delete()
      }
    },
    delete() {
      this.loading = true
      httpClient
        .delete('/package/api/v1/student/package/' + this.pck.IDStudentPackage)
        //.delete('http://localhost:13004/api/v1/student/package/' + this.pck.IDStudentPackage)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package deleted'})
          bus.$emit('refreshStudentPackages')
          bus.$emit('refreshStudentLessons')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error deleting package'})
        })
    },
  },
  components: {
    //CustomerPackageDeleteConfirm
  }
}
</script>