<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>{{ nameStudent }}</v-card-title>
      <v-card-text>
        Are you sure you want to remove {{ nameStudent }} from lesson?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="deleteStudentLesson()">
          Confirm Delete
        </v-btn>
        <v-btn color="primary" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      idLesson: 0,
      idStudent: 0,
      nameStudent: "",
    }
  },
  created () {
    bus.$on('openLessonStudentDeleteConfirm', (data) => {
      this.dialog = true
      this.idLesson = data.idLesson
      this.idStudent = data.idStudent
      this.nameStudent = data.nameStudent
    })
  },
  beforeDestroy () {
    bus.$off('openLessonStudentDeleteConfirm', null)
  },
  methods: {
    deleteStudentLesson: function () {
      this.loading = true
      httpClient
        .delete('/event/api/v1/lessonstudent/' + this.idLesson + '/' + this.idStudent)
        //.delete('http://localhost:13007/api/v1/lessonstudent/' + this.idLesson + '/' + idStudent)
        .then(() => {
          bus.$emit('snackBarMsg', {error: false, msg: 'Student removed successfully'})
          this.loading = false
          bus.$emit("refreshLessonItems")
          bus.$emit("refreshLessonTransports")
          bus.$emit("refreshLessonStudentList")
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error removing student'})
        })
    },
    closeDialog() {
      this.idLesson = 0
      this.idStudent = 0
      this.nameStudent = 0
      this.dialog = false
    }
  }
}
</script>
