<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="mx-auto" :loading="loading">
      <v-card-title>Registar horas de {{ staff.Name }}</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
              <v-text-field label="Horas" v-model="wStaffTime.Hours" type="number"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="add()">Registar</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      valid: false,
      staff: {},
      wStaffTime: {},
    }
  },
  created() {
    bus.$on('openWorkStaffTimeDialog', (data) => {
      this.staff = data.staff
      this.wStaffTime.IDWork = data.idWork
      this.wStaffTime.IDStaff = this.staff.ID
      this.dialog = true
    })
  },
  beforeDestroy () {
    bus.$off('openWorkStaffTimeDialog', null)
  },
  methods: {
    add () {
      this.loading = true
      let curDate = new Date().toISOString().substr(0, 19)

      this.wStaffTime.Hours = parseInt(this.wStaffTime.Hours)
      this.wStaffTime.Date = new Date().toISOString().substr(0, 10) + "T00:00:00Z"
      this.wStaffTime.CreationDate = curDate + "Z"
      this.wStaffTime.LastModifyDate = curDate + "Z"

      httpClient
        .post('/mix/api/v1/workstafftime', this.wStaffTime)
        //.post('http://localhost:13007/api/v1/workstafftime', this.wStaffTime)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.wStaffTime = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Horas do trabalhador registadas com sucesso'})
          bus.$emit('refreshWorks', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao registar horas do trabalhador'})
        })
    }
  }
}
</script>