<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="mx-auto" :loading="loading">
      <v-card-title>Adicionar combustivel</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
              <v-text-field label="Quantidade" v-model="workEquipFuel.Liters" type="number"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="register()">Registar</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      valid: false,
      workEquipFuel: {},
    }
  },
  created() {
    bus.$on('openWorkEquipFuelDialog', (data) => {
      this.workEquipFuel.IDWork = data.idWork
      this.workEquipFuel.IDEquipment = data.idEquipment
      this.dialog = true
    })
  },
  beforeDestroy () {
    bus.$off('openWorkEquipFuelDialog', null)
  },
  methods: {
    register() {
      this.loading = true
      let curDate = new Date().toISOString().substr(0, 19)

      // Liters is a STRING
      //this.workEquipFuel.Liters = parseInt(this.workEquipFuel.Liters)
      this.workEquipFuel.CreationDate = curDate + "Z"
      this.workEquipFuel.LastModifyDate = curDate + "Z"

      httpClient
        .post('/mix/api/v1/workequipmentfuel', this.workEquipFuel)
        //.post('http://localhost:13007/api/v1/workequipmentfuel', this.workEquipFuel)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.workEquipFuel = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Combutível adicionado com sucesso'})
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao adicionar combustível'})
        })
    }
  }
}
</script>