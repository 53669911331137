<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="400px">
      <v-card :loading="loading">
        <v-card-title>Reset {{ s.Name }} password</v-card-title>
        <v-card-text>
          <!--<v-alert type="warning">
            To reset the password an email will be sent to the staff email address
          </v-alert>-->
          <v-form v-model="valid">
            <v-text-field 
            v-model="s.Password"
            :rules="passRules"
            label="Nova password"
            type="password"
            required />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="confirmReset()">Reset</v-btn>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
//import StaffEventTable from './StaffEventTable.vue'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      s: {},
      valid: false,
      passRules: [
        v => !!v || 'Required'
      ],
    }
  },
  created () {
    bus.$on('changeStaffPassword', (data) => {
      this.s = data.staff
      this.openDialog()
    })
  },
  watch: {
    dialog: function (val) {
      if(!val) {
        this.s.Password = null
      }
    },
  },
  beforeDestroy () {
    bus.$off('changeStaffPassword', null)
  },
  methods: {
    openDialog () {
      this.dialog = true
    },
    confirmReset() {
      if(confirm("The staff will need to use the new password in the next login. Can we proceed?")) {
        this.resetPass()
      }
    },
    resetPass () {
      this.loading = true
      httpClient
        .put('/mix/api/v1/staff/reset/' + this.s.ID, this.s)
        //.put('http://localhost:13007/api/v1/staff/reset/' + this.s.ID, this.s)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.s = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Password alterada !'})
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao alterar password !'})
        })
    },
  }
}
</script>