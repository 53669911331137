<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-calendar-range</v-icon> Obras <WorkDialog :workAction="'create'" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.DateTime="{ item }">
        {{ formatDate(item.DateTime) }}
      </template>
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/work/' + item.ID">
          <v-chip
            class="mx-1"
            color="blue lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="blue darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
            View
          </v-chip>
        </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'
import WorkDialog from './WorkDialog'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      columns: [
        { text: 'Nome', align: 'left', sortable: true, value: 'Name' },
        { text: 'Options', sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50],
    }
  },
  created () {
    this.getAll()
    bus.$on('refreshWorks', () => { this.getAll() })
    this.search = this.$store.state.filters.work.list
  },
  beforeDestroy () {
    bus.$off('refreshWorks', null)
  },
  watch: {
    'search' () {
      this.$store.commit('filtersWorkList', this.search)
    }
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    getAll: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/works')
        //.get('http://localhost:13007/api/v1/works')
        .then((resp) => {
          this.loading = false
          this.rows = resp.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao obter obra'})
        })
    }
  },
  components: {
    WorkDialog
  }
}
</script>

<style>
a { text-decoration: none }
.v-small-dialog__content { height: 100px }
</style>