<template>
  <v-card :loading="loading">
    <v-card-title>Customers</v-card-title>
    <v-card-text>
      <v-layout style="flex-wrap: wrap">
        <v-flex v-for="customer in filteredRows" :key="customer.AppName" xs12 sm6 md4>
          <v-card class="mx-2 my-2" elevation="0" outlined>
            <v-card-title>
              {{ customer.AppName }}
            </v-card-title>
            <v-card-text style="padding: 0px; padding-left: 16px; padding-right: 16px">
              <div>
                <span>{{ customer.TotalLessons }}</span> <v-chip class="mx-1" color="success" small>+{{ customer.TotalLessonsLast30days }}</v-chip>
                <span>{{ customer.TotalStudents }}</span> <v-chip class="mx-1" color="success" small>+{{ customer.TotalStudentsLast30days }}</v-chip>
                <span>{{ customer.TotalStaffs }}</span> <v-chip class="mx-1" color="success" small>+{{ customer.TotalStaffsLast30days }}</v-chip>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              {{ customer.DBSizeMB }}<span class="mx-1"><small><strong>MBs</strong></small></span>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>

  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      filteredRows: []
    }
  },
  watch: {
    search: function (val) {
      this.filteredRows = this.rows.filter(function (a) {
        return a.Name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
    },
  },
  created () {
    this.getAll()
  },
  methods: {
    getAll: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/nmbot/apps/data', {})
        //.get('http://localhost:13007/api/v1/nmbot/apps/data', {})
        .then((r) => {
          this.loading = false
          this.rows = r.data
          this.filteredRows = this.rows
          this.filteredRows = this.filteredRows.sort((a, b) => (a > b ? 1 : -1))
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting apps and ports'})
        })
    },
  },
}
</script>
