<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-package-variant</v-icon> Packages
      <v-chip class="ma-1" color="blue lighten-5" text-color="black" @click="openNewDialog()">
        <v-avatar left class="blue darken-4" style="color: white !important">
          <v-icon small>mdi-plus</v-icon>
        </v-avatar>
        Create
      </v-chip>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-search" label="Search"
        single-line hide-details clearable></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :search="search"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
        <template v-slot:item.Name="{ item }">
          {{ item.Name }}
        </template>
        <template v-slot:item.Description="{ item }">
          {{ item.Description }}
        </template>
        <template v-slot:item.options="{ item }">
          <v-chip @click="view(item)" class="mx-1" color="blue lighten-5" text-color="black">
            <v-avatar left class="blue darken-4" style="color: white !important">
              <v-icon small>mdi-open-in-app</v-icon>
            </v-avatar>
            View
          </v-chip>
          <v-chip v-if="item.DeletedAt === null" @click="archive(item)"
            class="mx-1" color="red lighten-5" text-color="black">
            <v-avatar left class="red darken-4" style="color: white !important">
              <v-icon small>mdi-archive</v-icon>
            </v-avatar>
            Archive
          </v-chip>
          <v-chip v-if="item.DeletedAt !== null" @click="unArchive(item.ID)"
            class="mx-1" color="green lighten-5" text-color="black">
            <v-avatar
              left
              class="green darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-archive-outline</v-icon>
            </v-avatar>
            Activate
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>

    <PackageView />
    <PackageDialog />
    <PackageDeleteConfirm />

  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import PackageView from './PackageView'
import PackageDialog from './PackageDialog'
import PackageDeleteConfirm from './PackageDeleteConfirm'

export default {
  name: 'PackageList',
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      search: '',
      rows: [],
      rowsPerPageItems: [5, 10, 25, 50],
      columns: [
        { text: 'Name', align: 'left', value: 'Name' },
        { text: 'Price', align: 'left', value: 'Price' },
        { text: 'Amount sold', align: 'left', value: 'TotalUsage' },
        { text: 'Options', sortable: false, value: 'options', width: "250px" }
      ],
      apiVersion: 'v1',
    }
  },
  created () {
    this.getAll()
    bus.$on('refreshPackageList', () => { this.getAll() })
  },
  beforeDestroy () {
    bus.$off('refreshPackageList', null)
  },
  methods: {
    getAll: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/packages/historical')
        //.get('http://localhost:13007/api/v1/packages/historical')
        .then((resp) => {
          this.loading = false
          this.rows = []
          if (resp.data !== null) {
            this.rows = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting packages'})
        })
    },
    view: (pck) => {
      bus.$emit('openPackageDialog', true)
      bus.$emit('packageView', pck)
    },
    openDialog: function (pck) {
      bus.$emit('pckDialog', {isNew: false, pck: pck})
    },
    openNewDialog: function () {
      bus.$emit('pckDialog', {isNew: true, pck: {}})
    },
    confirm: function (pck) {
      bus.$emit('openPackageDeleteConfirm', {pck: pck})
    },
    archive: function (pck) {
      this.loading = true
      httpClient
        .delete('/package/api/v1/package/' + pck.ID)
        //.delete('http://localhost:13007/api/v1/package/' + pck.ID)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package archived'})
          bus.$emit('refreshPackageList', {})
          this.dialogDeleteConfirm = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error archiving package'})
        })
    },
    unArchive: function (id) {
      this.loading = true
      httpClient
        .put('/package/api/v1/package/' + id + "/undelete", {})
        //.put('http://localhost:13007/api/v1/package/' + id + "/undelete", {})
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package activated'})
          bus.$emit('refreshPackageList', {})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating package'})
        })
    },
  },
  components: {
    PackageView,
    PackageDialog,
    PackageDeleteConfirm,
  }
}
</script>
