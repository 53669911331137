<template>
  <v-dialog v-model="dialogLesson" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable style="max-height: 56px;">
    <v-card :loading="loading">
      <v-toolbar flat dark :color="titleColor">
        <v-btn icon @click="dialogLesson = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ eInfo.StyledDay }} - {{ eInfo.StyledStartTime }} to {{ eInfo.StyledEndTime }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <LessonView :propLessonID="eInfo.ID" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from '../../main'
import moment from 'moment'
import LessonView from './LessonView'

export default {
  data: () => ({
    dialogLesson: false,
    loading: false,
    eInfo: {},
    titleColor: "primary",
  }),
  created () {
    bus.$on('openLessonDialogFullscreen', (e) => { 
      this.titleColor = e.color
      this.eInfo = e
      this.eInfo.StyledDay = moment.parseZone(e.start).format('D MMM YYYY')
      this.eInfo.StyledStartTime = moment.parseZone(e.start).format('HH:mm')
      this.eInfo.StyledEndTime = moment.parseZone(e.end).format('HH:mm')
      this.dialogLesson = true
      bus.$emit('loadLesson', e.ID)
    })
    bus.$on('closeLessonDialogFullScreen', () => {
      this.dialogLesson = false
    })
  },
  beforeDestroy () {
    bus.$off('openLessonDialogFullscreen', null)
  },
  components: {
    LessonView,
  }
}
</script>