<template>
  <div>
    <v-dialog v-model="dialog" max-width="400px" persistent>
      <v-card
        :loading="loading"
        class="mx-auto elevation-1"
        max-width="400">

        <v-img 
          class="white--text align-end"
          src="@/assets/praia_ondas.jpg">
          <v-card-title
            class="justify-end">
            <img style="height: 50px;" src="@/assets/logo.png" />
          </v-card-title>
        </v-img>

        <v-card-title v-if="!registered">Register</v-card-title>

        <v-card-text v-if="!registered">
          <v-form>
            <v-text-field
              v-model="newUser.email"
              label="Email"
              name="nm-smart-email-new"
              prepend-icon="mdi-account"
              type="text"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              v-model="newUser.password"
              id="password"
              label="Password"
              name="nm-smart-password-new"
              prepend-icon="mdi-lock"
              type="password"
              autocomplete="new-password"
            ></v-text-field>
            <!--<v-text-field
              v-model="newUser.phone"
              label="Phone"
              name="nm-smart-phone-new"
              prepend-icon="mdi-phone"
              type="text"
              autocomplete="off"
            ></v-text-field>
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
            >
              <b>nmSmart</b> - You can enhance security and authentication by using your phone number.
            </v-alert>-->
          </v-form>
        </v-card-text>

        <v-card-actions v-if="!registered">
          <v-spacer></v-spacer>
          <v-btn text class="loginBtn" color="primary" @click="signup()">Register</v-btn>
          <v-btn text @click="dialog = false">Back</v-btn>
        </v-card-actions>

        <v-card-title v-if="registered">Account Activation</v-card-title>

        <v-card-text v-if="registered">
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
              class="blinking-element"
            >
              <b>nmSmart</b> - Please, check your email.
            </v-alert>
        </v-card-text>

        <v-card-actions v-if="registered">
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      registered: false,
      newUser: {},
    }
  },
  created () {
    bus.$on("signupDialog", () => this.dialog = true)
  },
  beforeDestroy () {
    bus.$off('signupDialog', null)
  },
  methods: {
    signup() {
      httpClient
        .post('/oauth/api/v1/register', this.newUser)
        //.post('http://localhost:13007/api/v1/register', this.newUser)
        .then(r => {
          if (!r.data.error) {
            this.registered = true
          }
          bus.$emit('snackBarMsg', {error: r.data.error, msg: r.data.message})
        })
        .catch(e => {
          console.error(e.message)
          bus.$emit('snackBarMsg', {error: true, msg: "Please contact the support"})
        })
    }
  }
}
</script>

<style scoped>
@keyframes blink {
  50% { opacity: 1; }
  70% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blinking-element {
  animation: blink 2s infinite; /* Adjust animation duration as needed */
}
</style>