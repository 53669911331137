<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable style="max-height: 56px;">
    
    <v-card :loading="loading">
      <v-toolbar flat dark color="primary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <WorkView />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from '../../main'
import WorkView from './WorkView'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
    }
  },
  created () {
    bus.$on('openWorkFullDialog', () => { this.dialog = true })
  },
  beforeDestroy () {
    bus.$off('openWorkFullDialog', null)
  },
  components: {
    WorkView
  }
}
</script>

<style>
/* BUG open - https://github.com/vuetifyjs/vuetify/issues/8606 */
header {
  max-height: 65px;
}
</style>