<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="80%">
      <template v-slot:activator="{ on }">
        <v-chip class="ma-2" color="blue lighten-5" v-on="on">
          <v-avatar class="blue darken-4" style="color: white !important">
            <v-icon small>mdi-plus</v-icon>
          </v-avatar>
        </v-chip>
      </template>
      <v-card :loading="loading">
        <v-card-title>Add student to lesson</v-card-title>
        <v-card-text>
          <v-row align="center" dense>
            <v-col cols="8" sm="8" md="9" lg="9">
              <v-form>
                <v-autocomplete
                  v-model="customerName"
                  label="Customer name"
                  :items="matchingCustomers"
                  item-text="Name"
                  item-value="ID"
                  :loading="loading"
                  :search-input.sync="searchQuery"
                  :filter="() => true"
                  :debounce-search="250"
                  @change="handleChange"
                ></v-autocomplete>
              </v-form>
            </v-col>
            <v-col cols="4" sm="4" md="3" lg="3">
              <StudentDialogCreate :buttonLable="'Create'" />
            </v-col>
          </v-row>
          <div v-if="lessonStudent.IDStudent">
            <v-row align="center" dense>
              <v-col cols="8" sm="8" md="9" lg="9">
                <v-select
                  v-model="lessonStudent.IDPackage"
                  :items="packageList"
                  item-text="Name"
                  item-value="IDStudentPackage"
                  item-disabled="Consumed"
                  label="Package"
                  required
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="4" sm="4" md="3" lg="3">
                <StudentPackageAdd :studentID="lessonStudent.IDStudent" :buttonLable="'Add'" />
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col cols="8" sm="8" md="9" lg="9">
                <v-select
                  v-model="lessonStudent.IDService"
                  :items="serviceList"
                  item-text="Name"
                  item-value="IDStudentService"
                  item-disabled="Consumed"
                  label="Service"
                  required
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="4" sm="4" md="3" lg="3">
                <StudentServiceAdd :studentID="lessonStudent.IDStudent" :buttonLable="'Add'" />
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-autocomplete
                  v-model="lessonStudent.IDSupplier"
                  :items="suppliers"
                  item-text="Name"
                  item-value="ID"
                  label="Supplier"
                  placeholder="Select Supplier.."
                  clearable
                  autocomplete="nope"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-autocomplete
                  v-model="lessonStudent.IDTransport"
                  :items="transports"
                  item-text="Name"
                  item-value="ID"
                  label="Transport"
                  placeholder="Select Transport.."
                  clearable
                  autocomplete="nope"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" dense>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  v-model="lessonStudent.IDBoard"
                  :items="boards"
                  item-text="Name"
                  item-value="ID"
                  label="Board"
                  placeholder="Select Board.."
                  clearable
                  autocomplete="nope"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-autocomplete
                  v-model="lessonStudent.IDWetsuit"
                  :items="wetsuits"
                  item-text="Name"
                  item-value="ID"
                  label="Wetsuit"
                  placeholder="Select Wetsuit.."
                  clearable
                  autocomplete="nope"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!customerID" @click="createLessonStudent()">Add</v-btn>
          <v-btn text @click="closeDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import _ from 'lodash'
import StudentDialogCreate from '../Student/StudentDialogCreate'
import StudentPackageAdd from '../Student/StudentPackageAdd'
import StudentServiceAdd from '../Student/StudentServiceAdd'

export default {
  props: {
    lessonID: Number,
    //studentList: Array,
  },
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    lessonStudent: {},
    customerID: 0,
    customer: {},
    matchingCustomers: [],
    packageList: [],
    serviceList: [],
    suppliers: [],
    transports: [],
    boards: [],
    wetsuits: [],
    studentCreated: {},
    studentPckCreated: {},
    studentSrvCreated: {},
    customerName: '',
    searchQuery: '',
  }),
  watch: {
    dialog(val) {
      if(val) {
        this.lessonStudent.IDLesson = this.lessonID
        this.getInitialUsers()
      }
    },
    searchQuery: _.debounce(function(newSearchQuery) {
      if (newSearchQuery && newSearchQuery.length >= 3) {
        this.searchStudents(newSearchQuery);
      } else {
        this.matchingStudents = [];
      }
    }, 250),
    customerID() {
      if(this.dialog !== false) {
        this.lessonStudent.IDStudent = this.customerID
        this.getStudent()
        this.getStudentPackages()
        this.getStudentServices()
        this.getSuppliers()
        this.getTransports()
        this.getItems()
      }
    }
  },
  created() {
    bus.$on('refreshLessonStudentDialogPackages', (data) => {
      this.studentPckCreated = data.package
      this.getStudentPackages()
    })
    bus.$on('refreshLessonStudentDialogServices', (data) => {
      this.studentSrvCreated = data.service
      this.getStudentServices()
    })
  },
  beforeDestroy () {
    //bus.$off('refreshLessonStudentDialogPackages', null)
    //bus.$off('refreshLessonStudentDialogServices', null)
  },
  methods: {
    getInitialUsers() {
      this.loading = true
      httpClient
        .get('/customer/api/v1/students/last/modified')
        //.get('http://localhost:13001/api/v1/students/last/modified')
        .then((r) => {
          this.loading = false
          this.matchingCustomers = r.data
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          bus.$emit('snackBarMsg', {error: true, msg: "Couldn't get students"})
        })
    },
    searchStudents(searchQuery) {
      this.loading = true
      httpClient
        .get('/customer/api/v1/students/search?name=' + searchQuery)
        //.get('http://localhost:13001/api/v1/students/search?name=' + searchQuery)
        .then((r) => {
          this.loading = false
          this.matchingCustomers = r.data
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          bus.$emit('snackBarMsg', {error: true, msg: "Couldn't get students"})
        })
    },
    async handleChange(selectedCustomerID) {
      this.customerID = selectedCustomerID
    },
    getStudent() {
      this.loading = true
      httpClient
        .get('/customer/api/v1/student/' + this.customerID)
        //.get('http://localhost:13001/api/v1/student/' + this.customerID)
        .then((r) => {
          this.loading = false
          this.student = r.data
          this.lessonStudent.IDSupplier = this.student.IDSupplier
          this.lessonStudent.IDBoard = this.student.IDBoard
          this.lessonStudent.IDWetsuit = this.student.IDWetsuit
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating customer'})
        })
    },
    getSuppliers() {
      this.loading = true
      httpClient
        .get('/mix/api/v1/suppliers')
        .then((r) => {
          this.loading = false
          this.suppliers = []
          if (r.data !== null) {
            this.suppliers = r.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting suppliers'})
        })
    },
    getTransports() {
      this.loading = true
      httpClient
        .get('/mix/api/v1/transports')
        //.get('http://localhost:13007/api/v1/transports')
        .then((resp) => {
          this.loading = false
          this.transports = []
          if (resp.data !== null) {
            this.transports = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting transports'})
        })
    },
    getItems() {
      httpClient
        .get('/mix/api/v1/items')
        .then((r) => {
          this.wetsuits = r.data.filter(item => item.Type === 'Wetsuit' )
          this.boards = r.data.filter(item => item.Type === 'Board' )
        })
        .catch(() => { 
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting items'})
        })
    },
    createLessonStudent() {
      this.loading = true
      httpClient
        .post('/event/api/v1/lessonstudent', this.lessonStudent)
        //.post('http://localhost:13007/api/v1/lessonstudent', this.lessonStudent)
        .then(() => {
          this.loading = false
          this.lessonStudent = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Student added to lesson'})
          bus.$emit('refreshLessonStudentList')
          bus.$emit("refreshLessonItems")
          bus.$emit("refreshLessonTransports")
          this.lessonStudent = {}
          this.customerName = ""
          this.closeDialog()
        })
        .catch((error) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: error.response.data.error})
        })
    },
    getStudentPackages() {
      this.loading = true
      httpClient
        .get('/package/api/v1/packages/student/' + this.customerID)
        .then((resp) => {
          this.loading = false
          if(resp) {
            this.packageList = resp.data.filter(pck => pck.Consumed !== true || pck.IDStudentPackage === this.lessonStudent.IDPackage)

            if(this.studentPckCreated !== undefined) {
              this.lessonStudent.IDPackage = this.studentPckCreated.ID
            }
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating student packages'})
        })
    },
    getStudentServices() {
      this.loading = true
      httpClient
        .get('/package/api/v1/services/student/' + this.customerID)
        .then((resp) => {
          this.loading = false
          if(resp) {
            this.serviceList = resp.data.filter(srv => srv.Consumed !== true || srv.IDStudentService === this.lessonStudent.IDService)

            if(this.studentSrvCreated !== undefined) {
              this.lessonStudent.IDService = this.studentSrvCreated.ID
            }
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student services'})
        })
    },
    closeDialog() {
      this.valid = false
      this.customerID = 0
      this.matchingCustomers = []
      this.lessonStudent = {}
      this.studentCreated = {}
      this.studentPckCreated = {}
      this.studentSrvCreated = {}
      this.dialog = false
    }
  },
  components: {
    StudentDialogCreate,
    StudentPackageAdd,
    StudentServiceAdd
  }
}
</script>