<template>
  <div>
    <v-toolbar elevation="1" rounded short>
      <v-toolbar-title>Welcome {{ userName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
            >
              <gb-flag :code="$i18n.locale" size="small" />
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-subheader>Languages</v-subheader>
              <v-divider></v-divider>
              <v-list-tile
                v-for="l in langs"
                :key="`lang-key-${l}`"
                @click="changeLanguage(l)"
                dense
              >
                <v-list-tile-title>
                  <gb-flag :code="l" size="small" />
                </v-list-tile-title><br/>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </div>

      <!--<v-chip @click="openChangePassDialog()"
        class="ma-0 mx-1" color="green lighten-5" text-color="black"
        style="margin-top: 40px; ">
        <v-avatar left
          class="green darken-4" style="color: white !important">
          <v-icon small>mdi-lock</v-icon>
        </v-avatar>
        Alterar Password
      </v-chip>-->
    </v-toolbar>

    <v-timeline v-if="isMobile()" dense align-top>
      <v-timeline-item
        v-for="item in items"
        :key="item.id"
        :color="item.color"
        :hide-dot="item.hidedot"
        small
      >
        <div v-if="item.type === 'day'"><h1>{{ getPrettyDate(item.date) }}</h1></div>
        <div v-if="item.type === 'lesson'">
          <h2 :class="`headline font-weight-light mb-4 orange--text`">
            {{getTime(item.lesson.DateTime)}}
          </h2>
          <div>
            <h3 style="font-weight: bold">{{ item.lesson.Place }} <span v-if="item.lesson.Type"> - {{ item.lesson.Type }}</span></h3>
            <h5>Comments</h5>
            <p>{{ item.lesson.Comments }}</p>
            <h5><span v-if="item.lesson.Staffs">{{ item.lesson.Staffs.length }}</span> Instructors</h5>
            <p v-for="s in item.lesson.Staffs" :key="s.ID" style="margin: 0px; padding-left: 5px;font-size: 13px; margin-bottom: 0px">{{ s.Name }} <span v-if="s.Phone">- <a :href="`tel:s.Phone`">{{ s.Phone }}</a></span></p>
            <h5 style="padding-top: 10px"><span v-if="item.lesson.Students">{{ item.lesson.Students.length }}</span> Students</h5>
            <p v-for="s in item.lesson.Students" :key="s.ID" style="font-size: 13px; margin-bottom: 0px">
              <span>{{ s.Name }}</span> <span v-if="s.Phone">- <a :href="`tel:s.Phone`">{{ s.Phone }}</a></span><br />
              <span v-if="s.Board"><v-chip class="ma-1" color="orange" text-color="white" x-small>Board</v-chip>{{s.Board}}</span><br/>
              <span v-if="s.Wetsuit"><v-chip class="ma-1" color="orange" text-color="white" x-small>Wetsuit</v-chip>{{s.Wetsuit}}</span><br/>
              <span v-if="s.Transport"><v-chip class="ma-1" color="green" text-color="white" x-small>Transport</v-chip>{{s.Transport}}</span><br/>
            </p>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>

    <v-card class="my-3" v-if="!isMobile()" elevation="0">
      <v-card-title>{{ $t("message.today_events") }}</v-card-title>
      <v-card-text>
        <v-timeline>
          <v-timeline-item
            v-for="item in items"
            :key="item.id"
            color="blue"
            small
          >
            <div v-if="item.type === 'day'"><h1>{{ getPrettyDate(item.date) }}</h1></div>
            <template v-slot:opposite v-if="item.type === 'lesson'">
              <span
                class="headline font-weight-bold blue--text"
                v-text="getTime(item.lesson.DateTime)"
              ></span>
            </template>
            <v-card class="elevation-2" v-if="item.type === 'lesson'">
              <v-card-title class="headline">{{ item.lesson.Place }}</v-card-title>
              <v-card-subtitle v-if="item.lesson.Type">{{ item.lesson.Type }}</v-card-subtitle>
              <v-card-text>
                <h4>Comments</h4>
                <p>{{ item.lesson.Comments }}</p>
                <h4><span v-if="item.lesson.Staffs">{{ item.lesson.Staffs.length }}</span> Instructors</h4>
                <p v-for="s in item.lesson.Staffs" :key="s.ID" style="margin: 0px; padding-left: 5px">{{ s.Name }}</p>
                <h4 style="padding-top: 10px" v-if="item.lesson.Students">{{ item.lesson.Students.length }} Students</h4>
                <p v-for="s in item.lesson.Students" :key="s.ID">
                  <span>{{ s.Name }}</span> <span v-if="s.Phone">- <a :href="`tel:s.Phone`">{{ s.Phone }}</a> <br/></span>
                  <!--<span>{{ s.Package }}</span> <span>{{ s.Service }}</span><br />-->
                  <span v-if="s.Board"><v-chip class="ma-1" color="orange" text-color="white" x-small>Board</v-chip>{{s.Board}} <br/></span>
                  <span v-if="s.Wetsuit"><v-chip class="ma-1" color="orange" text-color="white" x-small>Wetsuit</v-chip>{{s.Wetsuit}} <br/></span>
                  <span v-if="s.Transport"><v-chip class="ma-1" color="green" text-color="white" x-small>Transport</v-chip>{{s.Transport}} <br/></span>
                </p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-dialog v-model="changePassDialog" scrollable max-width="80%">
      <v-card :loading="loadingChangePass">
        <v-card-title>Alterar password</v-card-title>
        <v-card-text>
          <v-alert type="warning">
            Por favor memorize a sua password
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="changePass()">Change</v-btn>
          <v-btn color="darken-1" text @click="changePassDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'

export default {
  data() {
    return {
      loading: false,
      loadingChangePass: false,
      changePassDialog: false,
      langs: ['pt', 'gb', 'fr'],
      userName: '',
      messages: 0,
      show: false,
      date: new Date().toISOString().substr(0, 10),
      lessons: [],
      items: [],
    };
  },
  created () {
    this.userName = this.$store.state.userName
    this.getTodayLessons(moment.parseZone(this.date).format('YYYY-MM-DD'))
  },
  mounted() {
    this.callNExt5Dyas()
  },
  methods: {
    changeLanguage(l) {
      this.$i18n.locale = l
    },
    callNExt5Dyas() {
      //this.getTodayLessons(moment.parseZone(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD'))
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      return false
    },
    getPrettyDate(d) {
      return moment.parseZone(d).format('DD MMM YYYY')
    },
    getTime(d) {
      return moment.parseZone(d).format('HH:mm')
    },
    openChangePassDialog() {
      this.changePassDialog = true
    },
    getTodayLessons(day) {
      this.loading = true
      httpClient
        .get('/event/api/v1/lessons/students/day/' + day)
        //.get('http://localhost:13007/api/v1/lessons/students/day/' + day)
        .then((resp) => {
          this.loading = false
          if(resp.data !== null) {
            this.lessons = resp.data
            this.items.push({id: Math.random(), hidedot: true, type: 'day', lesson: null, date: day})

            this.lessons.forEach(e => {
              this.items.push({id: e.ID, hidedot: false, type: 'lesson', lesson: e, date: null})
            })
          }
        })
        .catch((error) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons - ' + error})
        })
    }
  }
}
</script>