<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>{{ pck.Name }}</v-card-title>
      <v-card-text>
        <p>{{ pck.Description }}</p>
        <v-alert v-if="pck.TotalUsage > 20"
          dense outlined type="error"
        >
          Amount sold <strong>{{ pck.TotalUsage }}</strong>
        </v-alert>
        <v-alert v-if="pck.TotalUsage > 0 && pck.TotalUsage <= 20"
          dense border="left" type="warning"
        >
          Amount sold <strong>{{ pck.TotalUsage }}</strong>
        </v-alert>
        <v-alert v-if="pck.TotalUsage == 0"
          dense text type="success"
        >
          Amount sold <strong>{{ pck.TotalUsage }}</strong>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="deletePck()">
          Confirm Delete
        </v-btn>
        <v-btn color="primary" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      checkbox: false,
      pck: {},
    }
  },
  created () {
    bus.$on('openPackageDeleteConfirm', (data) => {
      this.dialog = true
      this.pck = data.pck
    })
  },
  beforeDestroy () {
    bus.$off('openPackageDeleteConfirm', null)
  },
  methods: {
    deletePck: function () {
      this.loading = true
      httpClient
        .delete('/package/api/v1/package/' + this.pck.ID, { data: this.pck })
        //.delete('http://localhost:13004/api/v1/package/' + this.pck.ID, { data: this.pck })
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package deleted'})
          bus.$emit('refreshPackageList', null)
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error deleting package'})
        })
    }
  }
}
</script>
