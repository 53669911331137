var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-range")]),_vm._v(" Obras "),_c('WorkDialog',{attrs:{"workAction":'create'}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.rows,"search":_vm.search,"items-per-page":10,"footer-props":{
      itemsPerPageOptions: _vm.rowsPerPageItems,
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }},scopedSlots:_vm._u([{key:"item.DateTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateTime))+" ")]}},{key:"item.options",fn:function({ item }){return [_c('router-link',{attrs:{"to":'/work/' + item.ID}},[_c('v-chip',{staticClass:"mx-1",attrs:{"color":"blue lighten-5","text-color":"black"}},[_c('v-avatar',{staticClass:"blue darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-forward")])],1),_vm._v(" View ")],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }