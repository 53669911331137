<template>
  <div>
    <v-dialog v-model="dialogSrv" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-chip class="ma-1" color="blue lighten-5" text-color="black" v-on="on">
          <v-avatar left class="blue darken-4" style="color: white !important">
            <v-icon small>mdi-plus</v-icon>
          </v-avatar>
          {{ buttonLable }}
        </v-chip>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Select service</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8">
                <v-select
                  v-model="studentService.IDService"
                  :items="serviceList"
                  item-text="Name"
                  item-value="ID"
                  item-disabled="Consumed"
                  label="Service"
                  required
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Quantity" class="purple-input" type="number" v-model.number="studentService.Quantity" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createStudentService()">Add</v-btn>
          <v-btn color="darken-1" text @click="dialogSrv = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    studentID: Number,
    buttonLable: String,
  },
  data: () => ({
    dialogSrv: false,
    serviceList: [],
    studentService: {},
    loading: false,
  }),
  watch: {
    dialogSrv: function (val) {
      if(val) {
        this.studentService = {}
        this.getServices()
        this.studentService.IDStudent = this.studentID
      }
    }
  },
  methods: {
    getServices: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/services')
        //.get('http://localhost:13007/api/v1/services')
        .then((resp) => {
          this.loading = false
          if(resp) {    
            this.serviceList = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting services'})
        })
    },
    createStudentService: function () {
      if(this.studentService.Quantity === undefined || this.studentService.Quantity === 0) {
        bus.$emit('snackBarMsg', {error: true, msg: 'Please insert the quantity'})
        return
      }
      this.loading = true
      httpClient
        .post('/package/api/v1/services/student', this.studentService)
        //.post('http://localhost:13004/api/v1/services/student', this.studentService)
        .then((r) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service added to student'})
          bus.$emit('refreshStudentServices')
          bus.$emit('refreshLessonStudentDialogServices', {service: r.data})
          bus.$emit('refreshFormPackageServiceServices', {service: r.data})
          this.dialogSrv = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating student service'})
        })
    },
  },
}
</script>