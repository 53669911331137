<template>
  <v-card class="my-0" :loading="loading">
    <v-card-title>
    <v-icon left>mdi-briefcase-account</v-icon> Staff <StaffDialog :staffAction="'create'" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Procurar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.options="{ item }">
        <!--<SendSMS :phone="item.Phone" :name="item.Name" />
        <SendEmail :email="item.Email" :name="item.Name" />-->
        <router-link v-bind:to="'/staff/' + item.ID">
          <v-chip
            class="mx-1"
            color="blue lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="blue darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
            View
          </v-chip>
        </router-link>
        <!--<v-btn icon @click="confirmDelete(item)">
          <v-icon color="info">mdi-delete</v-icon>
        </v-btn>-->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import StaffDialog from './StaffDialog.vue'
//import SendEmail from '../Generic/SendEmail.vue'
//import SendSMS from '../Generic/SendSMS.vue'

export default {
  data () {
    return {
      loading: false,
      rows: [],
      rowsPerPageItems: [5, 10, 25, 50],
      columns: [
        { text: 'Nome', align: 'left', value: 'Name' },
        { text: 'Perfil', value: 'Role' },
        { text: 'Total Events', value: "totalEvents"},
        { text: '', align: 'left', sortable: false, value: 'options' }
      ],
      search: '',
    }
  },
  created () {
    bus.$on('refreshStaffList', () => { this.getAll() })
    this.getAll()
  },
  beforeDestroy () {
    bus.$off('refreshStaffList', null)
  },
  methods: {
    getAll () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/staffs')
        //.get('http://localhost:13007/api/v1/staffs')
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting staffs'})
        })
    },
  },
  components: {
    StaffDialog,
    //SendEmail,
    //SendSMS
  }
}
</script>
