<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="mx-auto" :loading="loading">
      <v-card-title>Registar horas</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="nm-NoPaddingBot">
              <v-text-field label="Horas de funcionamento" v-model="workEquipTime.Hours" type="number"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="register()">Registar</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      valid: false,
      workEquipTime: {},
    }
  },
  created() {
    bus.$on('openWorkEquipTimeDialog', (data) => {
      this.workEquipTime.IDWork = data.idWork
      this.workEquipTime.IDEquipment = data.idEquipment
      this.dialog = true
    })
  },
  beforeDestroy () {
    bus.$off('openWorkEquipTimeDialog', null)
  },
  methods: {
    register() {
      this.loading = true
      this.workEquipTime.Hours = parseInt(this.workEquipTime.Hours)

      httpClient
        .post('/mix/api/v1/workequiptime', this.workEquipTime)
        //.post('http://localhost:13007/api/v1/workequiptime', this.workEquipTime)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Horas de funcionamento registadas com sucesso'})
          this.workEquipTime = {}
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao registar horas de funcionamento'})
        })
    }
  }
}
</script>