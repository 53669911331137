<template>
  <v-dialog v-model="dialog" max-width="80%">
    <template v-slot:activator="{ on }">
      <v-chip class="mx-2" color="blue lighten-5" v-on="on">
        <v-avatar class="blue darken-4" style="color: white !important">
          <v-icon small>mdi-pencil</v-icon>
        </v-avatar>
      </v-chip>
    </template>
    <v-card class="mx-auto" :loading="loading">
      <v-card-title><span>Update comments</span></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-textarea
              rows="5"
              counter
              name="input-7-4"
              label="Comments"
              v-model="lessonComments.Comment"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="saveComments()">Update</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    lessonDetails: {}
  },
  data () {
    return {
      loading: false,
      lesson: {},
      lessonComments: {},
      date: new Date().toISOString().substr(0, 10),
      error: '',
      dialog: false,
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        this.lesson = this.lessonDetails
        this.getComments()
      }
    },
  },
  methods: {
    getComments () {
      httpClient
        .get('/event/api/v1/comment/lesson/' + this.lesson.ID)
        //.get('http://localhost:13007/api/v1/comment/lesson/' + this.lesson.ID)
        .then((r) => {
          this.lessonComments = r.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson comments'})
        })
    },
    saveComments () {
      httpClient
        .put('/event/api/v1/comments/' + this.lessonComments.ID, this.lessonComments)
        //.put('http://localhost:13007/api/v1/comments/' + this.lesson.ID, this.lessonComments)
        .then((r) => {
          this.lessonComments = r.data
          bus.$emit('snackBarMsg', {error: false, msg: 'Comments updated'})
          bus.$emit('refreshLessonComments', null)
          this.dialog = false
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson comments'})
        })
    },
  }
}
</script>