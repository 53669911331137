<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="500">
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-1"
          color="blue lighten-5"
          text-color="black"
          v-on="on"
        >
          <v-avatar left
            class="blue darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-avatar>
          Add
        </v-chip>
      </template>
      <v-card :loading="loading">
        <v-card-title>Add student to lesson</v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-autocomplete
              v-model="lessonStudent.IDLesson"
              label="Lessons"
              :items="formattedLessons"
              item-text="displayText"
              item-value="ID"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="createLessonStudent()">Create</v-btn>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'

export default {
  props: {
    studentID: Number
  },
  data: () => ({
    loading: false,
    dialog: false,
    lessonStudent: {},
    valid: false,
    lessons: [],
  }),
  watch: {
    dialog: function (val) {
      if(val) {
        this.lessonStudent.IDStudent = this.studentID
        this.getLessons()
      }
    },
  },
  computed: {
    formattedLessons() {
      return this.lessons.map((lesson) => ({
        ...lesson,
        displayText: `${this.formatDate(lesson.DateTime)} - (${lesson.Minutes}m) ${lesson.Place ? lesson.Place : 'No place specified'}`,
      }));
    },
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD MMM YYYY HH:mm')
    },
    getLessons: function () {
      this.loading = true
      httpClient
        .get('/event/api/v1/lessons?pageNumber=1&pageSize=1000000')
        //.get('http://localhost:13007/api/v1/lessons?studentID=' + this.studentID)
        .then((r) => {
          this.loading = false
          this.lessons = r.data.events
        })
        .catch((error) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: error.response.data.error})
        })
    },
    createLessonStudent: function () {
      this.loading = true
      httpClient
        .post('/event/api/v1/lessonstudent', this.lessonStudent)
        //.post('http://localhost:13007/api/v1/lessonstudent', this.lessonStudent)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Student added to lesson'})
          this.lessonStudent = {}
          this.dialog = false
          bus.$emit('refreshStudentLessons', null)
          bus.$emit('refreshStudentServices')
          bus.$emit('refreshStudentPackages')
        })
        .catch((error) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: error.response.data.error})
        })
    }
  }
}
</script>