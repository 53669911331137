<template>
  <div>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn fab text small @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-btn fab text small @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <LessonDialog />
            <v-spacer></v-spacer>
            <v-menu bottom right v-if="!isMobile()">
              <template v-slot:activator="{ on }">
                <v-btn
                  outlined
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet> <!-- height="700" -->
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :first-interval=7
            :interval-count=15
            :weekdays="weekdays"
            :events="events"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            :now="today"
            :type="type"
            :short-months="true"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:time="createLesson"
            @change="updateRange"
          >
            <template v-slot:event="{ event }">
              <div style="padding: 3px">
                <!-- :ref="event.ID" @mouseover="lessonMouseOver(event)" @mouseleave="lessonMouseLeave(event)" -->
                <!--<div><b>{{ event.type }}</b> at <b>{{ event.place }}</b></div>-->
                <div v-for="(s) in event.lesson.Staffs" :key="s.ID" style="font-size: 16px; font-variant: small-caps; text-align: center">{{ s.Name }}</div>
                <div style="font-size: 14px; font-variant: small-caps; text-align: center">{{ event.lesson.Minutes }}m <span v-if="event.lesson.Place">@</span> {{ event.lesson.Place }}</div>
                <div v-for="(s) in event.lesson.Students" :key="s.ID">{{ s.Name }}</div>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>

    <LessonDialogFullscreen />
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import LessonDialog from '../Lesson/LessonDialog'
import LessonDialogFullscreen from '../Lesson/LessonDialogFullscreen'

export default {
  data: () => ({
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    eventColors: Array('#03a9f4', '#d9534f', '#5cb85c', '#ed9c28', '#39b3d7'),
    /*
    eventColors: Array('#03a9f4', '#d9534f', '#5cb85c', '#ed9c28', 'red darken-1', 'red darken-2', 'red darken-3', 
      'pink', 'pink darken-1', 'pink darken-2', 'pink darken-3', 'pink darken-4', 
      'purple', 'purple darken-1', 'purple darken-2', 'purple darken-3', 
      'deep-purple', 'deep-purple darken-1', 'deep-purple darken-2', 'deep-purple darken-3', 'deep-purple darken-4', 
      'indigo', 'indigo darken-1', 'indigo darken-2', 'indigo darken-3',
      'blue', 'blue darken-1', 'blue darken-2', 'blue darken-3',
      'light-blue', 'light-blue darken-1', 'light-blue darken-2', 'light-blue darken-3', 'light-blue darken-4',
      'cyan', 'cyan darken-1', 'cyan darken-2', 'cyan darken-3',
      'teal', 'teal darken-1', 'teal darken-2', 'teal darken-3',
      'green', 'green darken-1', 'green darken-2', 'green darken-3',
      'light-green', 'light-green darken-1', 'light-green darken-2', 'light-green darken-3',
      'lime', 'lime darken-1', 'lime darken-2', 'lime darken-3', 'lime darken-4',
      'yellow darken-2', 'yellow darken-3',
      'amber', 'amber darken-1', 'amber darken-2', 'amber darken-3',
      'orange', 'orange darken-1', 'orange darken-2', 'orange darken-3',
      'deep-orange', 'deep-orange darken-1', 'deep-orange darken-2', 'deep-orange darken-3', 'deep-orange darken-4',
      'brown', 'brown darken-1', 'brown darken-2', 'brown darken-3', 'blue-grey', 'blue-grey darken-1', 'blue-grey darken-2', 'blue-grey darken-3', 'blue-grey darken-4'),
    */
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    type: 'week',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    lessons: [],
    events: [],
    createdLesson: [],
    activeLessonInitHeight: 0,
  }),
  computed: {
    title () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      //const endMonth = this.monthFormatter(end)
      //const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      //const endYear = end.year
      //const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      if(this.isMobile()) {
        return `${start.day}.${start.month}.${startYear}`
      }

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startDay} - ${endDay} ${startMonth} ${startYear}`
        case 'day':
          return `${startDay} ${startMonth} ${startYear}`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'short',
      })
    },
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  created () {
    this.type = this.$store.state.calendar.type
    if(this.isMobile()) {
      this.viewDay(this.today)
    }
    bus.$on('refreshCalendarLessons', () => {
      this.getLessonsRange()
      /*if (data === null) {
        this.getLessonsRange()
      } else {
        this.createdLesson = data.lesson
        //this.getLessonsRange()
        this.addEventToList(data.lesson)
      }*/
    })
    //bus.$on('refreshCalendarEvents', () => this.getEventsRange())
  },
  beforeDestroy () {
    //bus.$off('refreshCalendarLessons', null)
    //bus.$off('refreshCalendarEvents', null)
  },
  methods: {
    addEventToList(e) {
      let place = (e.Place) ? e.Place : '..'
      let type = (e.Type) ? e.Type: 'Lesson'
      let color = this.eventColors[Math.floor(Math.random()*this.eventColors.length)]

      this.events.push({
        ID: e.ID,
        lesson: e,
        type: type,
        place: place,
        start: moment.parseZone(e.DateTime).format('YYYY-MM-DD HH:mm'),
        end: moment.parseZone(e.DateTime).add(e.Minutes, 'm').format('YYYY-MM-DD HH:mm'),
        color: color,
      })
    },
    isOldSmartSurf() {
      return (this.$store.state.database === 'cascaissurfschool' || this.$store.state.database === 'demosmartsurf')
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      
      return false
    },
    createLesson(e) {
      bus.$emit('createFromCalendar', {date: e.date, time: e.hour + ":00"})
    },
    getLessonsRange() {
      httpClient
        .get('/event/api/v1/lessons/start/' + this.start.date + '/end/' + this.end.date)
        //.get('http://localhost:13007/api/v1/lessons/start/' + this.start.date + '/end/' + this.end.date)
        .then((resp) => {
          this.events = []
          if(resp.data !== null) {
            resp.data.forEach(e => {
              this.addEventToList(e)
            })
          }
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons'})
        })
    },
    lessonMouseOver(lesson) {

        console.log(this.$refs[lesson.ID].parentElement.style.height)
        this.activeLessonInitHeight = this.$refs[lesson.ID].parentElement.style.height
        console.log("activeLessonInitHeight", this.activeLessonInitHeight)

        this.$refs[lesson.ID].parentElement.style.height = '360px'

      /*
        $(this).animate({ margin: 0, height: "+=160" });
      */
    },
    lessonMouseLeave(lesson) {
      console.log("LEAVING")

      console.log(this.activeLessonInitHeight)

      this.$refs[lesson.ID].parentElement.style.height = this.activeLessonInitHeight


      /*
        $(this).animate({ margin: 0, height: "-=160" });
      */
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {

      bus.$emit('openLessonDialogFullscreen', event)

      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start
      this.end = end

      //if ( this.isOldSmartSurf() ) {
        this.getLessonsRange()
      /*} else {
        this.getEventsRange()
      }*/

      this.$store.commit('calendarStart', start)
      this.$store.commit('calendarEnd', end)
      this.$store.commit('calendarType', this.type)
    },
    nth (d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    /*getEventsRange() {
      axios
        .get(this.MSUrl + 'event/api/v1/events/start/' + this.start.date + '/end/' + this.end.date)
        //.get('http://localhost:13007/api/v1/events/start/' + this.start.date + '/end/' + this.end.date)
        .then((resp) => {
          this.events = []
          if(resp.data !== null) {
            resp.data.forEach(e => {
              //let totalStaff = 0, totalCustomer = 0
              let title = '', type = '', details = '', popupTitle = ''

              //totalStaff = (e.TotalStaff) ? e.TotalStaff : 0
              //totalCustomer = (e.TotalCustomer) ? e.TotalCustomer : 0
              
              type = (e.Type) ? e.Type: 'Event'

              //title = type + 
              //  ' <i aria-hidden="true" class="v-icon notranslate mdi mdi-account theme--light"></i>' + 
              //  totalStaff + 
              //  ' <i aria-hidden="true" class="v-icon notranslate mdi mdi-account-badge theme--light"></i>' + 
              //  totalCustomer + " "
              
              title = type

              details = (e.Place) ? type + ' at ' + e.Place + '\n' : type + '\n'
              
              popupTitle = '<b>' + moment.parseZone(e.StartAt).format('HH:mm') + '</b> to <b>' + moment.parseZone(e.EndAt).format('HH:mm') + '</b>'

              this.events.push({
                ID: e.ID,
                name: title,
                popupTitle: popupTitle,
                details: details,
                start: moment.parseZone(e.StartAt).format('YYYY-MM-DD HH:mm'),
                end: moment.parseZone(e.EndAt).add(e.Minutes, 'm').format('YYYY-MM-DD HH:mm'),
                color: this.eventColors[Math.floor(Math.random()*this.eventColors.length)],
              })
            })
          }
        })
        .catch((error) => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons - ' + error})
        })
    },*/
  },
  components: {
    LessonDialogFullscreen,
    LessonDialog
  }
}
</script>