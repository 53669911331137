<template>
  <v-card :loading="loading" elevation="1">
    <v-card-title>
      <v-tooltip bottom v-if="!showUsed">
        <template v-slot:activator="{ on }">
          <v-icon left v-on="on" @click="showUsed = !showUsed">mdi-package-variant</v-icon>
        </template>
        <span>View used</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showUsed">
        <template v-slot:activator="{ on }">
          <v-icon left v-on="on" @click="showUsed = !showUsed">mdi-package-variant-closed</v-icon>
        </template>
        <span>Hide used</span>
      </v-tooltip>
      Packages <StudentPackageAdd :studentID="customerID" :buttonLable="'Add'" />
    </v-card-title>
    <v-card-text>
      <div>
        <span color="cyan darken-3" v-for="pck in packagesFiltered" :key="pck.IDStudentPackage">
          <v-chip
            v-if="pck.Consumed != 1"
            class="ma-1"
            color="green lighten-5"
            text-color="black"
            v-bind:style="{'border': '1px solid #E8F5E9', 'background': 'linear-gradient(to left, #ffffff ' + (100-pck.UsagePercentage) + '%, #E8F5E9 ' + pck.UsagePercentage + '%)'}"
            @click="openStudentPackageDetails(pck)"
          >
            <v-avatar
              left
              class="green darken-4"
              style="color: white !important"
            >
              {{ pck.Quantity }}
            </v-avatar>
            {{ pck.Name }}
            <v-avatar
              right
              style="font-size: 10px"
            >
              {{ pck.UsagePercentage }}%
            </v-avatar>
          </v-chip>
          <v-chip
            v-if="pck.Consumed == 1"
            class="ma-1"
            color="red lighten-5"
            text-color="black"
            @click="openStudentPackageDetails(pck)"
          >
            <v-avatar
              left
              class="red darken-4"
              style="color: white !important"
            >
              {{ pck.Quantity }}
            </v-avatar>
            {{ pck.Name }}
            <v-avatar
              v-if="pck.UsagePercentage > 100"
              right
              style="color: #B71C1C; font-size: 10px; font-weight: bold"
            >
              {{ pck.UsagePercentage }}%
            </v-avatar>
          </v-chip>
        </span>
        <v-chip
          class="ma-1"
          color="yellow lighten-4"
          text-color="black"
          @click="showUsed = !showUsed"
        >
          <v-avatar
            left
            class="yellow darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-dots-horizontal</v-icon>
          </v-avatar>
          <span v-if="!showUsed">Show used</span><span v-if="showUsed">Hide used</span>
        </v-chip>
        <StudentPackageDetailsView />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import StudentPackageAdd from './StudentPackageAdd'
import StudentPackageDetailsView from '../Student/StudentPackageDetailsView'

export default {
  props: {
    customerID: Number
  },
  data () {
    return {
      search: '',
      packages: [],
      packagesFiltered: [],
      loading: false,
      showUsed: false,
    }
  },
  watch: {
    showUsed: function (val) {
      if(val) {
        this.packagesFiltered = this.packages
      } else {
        this.packagesFiltered = this.packages.filter(pck => pck.Consumed !== true)
      }
    }
  },
  mounted: function () {
    bus.$on('refreshStudentPackages', () => { this.getStudentPackages(this.customerID) })
    this.getStudentPackages(this.customerID)
  },
  beforeDestroy () {
    bus.$off('refreshStudentPackages', null)
  },
  methods: {
    getStudentPackages: function (customerID) {
      this.loading = true
      httpClient
        .get('/package/api/v1/packages/student/' + customerID)
        //.get('http://localhost:13004/api/v1/packages/student/' + customerID)
        .then((resp) => {
          this.loading = false
          this.packages = resp.data
          if(this.showUsed === false) {
            this.packagesFiltered = this.packages.filter(pck => pck.Consumed !== true)
            return
          }
          this.packagesFiltered = this.packages
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student packages'})
        })
    },
    openStudentPackageDetails: function (pck) {
      bus.$emit('openStudentPackageDetails', {pck: pck})
    }
  },
  components: {
    StudentPackageAdd,
    StudentPackageDetailsView
  }
}
</script>
