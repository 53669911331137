import axios from 'axios'
import store from '../store'
import { bus } from '../main'
import router from '../router'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
})

httpClient.interceptors.request.use(function (config) {
  config.headers.common["NM"] = store.state.user
  config.headers.Authorization = `Bearer ${store.state.token}`
  return config;
}, function (error) {
  return Promise.reject(error);
})

httpClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status === 403) {
    router.go('/')
    bus.$emit('sideBarLogout')
  }
  return Promise.reject(error);
})

export default httpClient