<template>
  <v-card :loading="loading">
    <v-card-title>
      nmSmart users
      <v-spacer></v-spacer>
      <v-text-field v-model="search"
        append-icon="mdi-search" label="Search" single-line hide-details clearable></v-text-field>
    </v-card-title>
    <v-card-text v-if="false">
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3"
          v-for="user in rows" :key="user.Email">
          <v-card outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">{{ user.Email }}</div>
                <v-list-item-title class="headline mb-1">{{ user.UpdatedDate }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.Name }} {{ user.Surname }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn text>{{ user.StaffType }}</v-btn>
              <v-btn text>Button</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :sort-by="['UpdatedDate']"
        :sort-desc="[true]"
        :search="search"
        :items-per-page="5"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
        <template v-slot:item.UpdatedDate="{ item }">
          {{ formatDate(item.UpdatedDate) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import moment from 'moment'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      columns: [
        { text: 'Name', align: 'left', value: 'Name' },
        { text: 'Email', align: 'left', value: 'Email' },
        { text: 'Application', value: 'Database' },
        { text: 'Last Activity', value: 'UpdatedDate' }
      ],
      rowsPerPageItems: [5, 10, 20, 50]
    }
  },
  mounted: function () {
    this.getUsers()
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    getUsers: function () {
      this.loading = true
      httpClient
        .get('/oauth/api/v1/nm/users?pageSize=10')
        //.get('http://localhost:13007/api/v1/nm/users')
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting users'})
        })
    },
  }
}
</script>
