<template>
  <span>
    <v-chip class="ma-1" color="blue lighten-5" text-color="black" @click="dialog = true">
      <v-avatar left class="blue darken-4" style="color: white !important">
        <v-icon small>mdi-plus</v-icon>
      </v-avatar>
      Adicionar
    </v-chip>
    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card :loading="loading">
        <v-card-title>Adicionar trabalhador à obra</v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-autocomplete v-model="workStaff.IDStaff" label="Selectionar trabalhador"
              :items="staffList"
              item-text="Name"
              item-value="ID"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="addStaff()">Adicionar</v-btn>
          <v-btn text @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    idWork: Number
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    workStaff: {},
    staffList: [],
  }),
  watch: {
    dialog: function (val) {
      if(val) {
        this.workStaff.IDWork = this.idWork
        this.getStaffs()
      }
    },
  },
  methods: {
    getStaffs: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/staffs')
        //.get('http://localhost:13007/api/v1/staffs')
        .then((r) => {
          this.loading = false
          this.staffList = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao carregar trabalhadores'})
        })
    },
    addStaff: function () {
      this.loading = true
      httpClient
        .post('/mix/api/v1/workstaff', this.workStaff)
        //.post('http://localhost:13007/api/v1/workstaff', this.workStaff)
        .then(() => {
          this.loading = false
          bus.$emit('refreshWorkStaffs', null)
          bus.$emit('snackBarMsg', {error: false, msg: 'Trabalhador adicionar à obra'})
          this.workStaff = {}
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao adicionar trabalhador à obra'})
        })
    }
  }
}
</script>