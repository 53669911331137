<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title>
      <v-icon left>mdi-briefcase-account</v-icon> Trabalhadores
      <Create :idWork="this.idWork" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Procurar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <div class="layout row" style="flex-wrap: wrap !important;">
        <div class="flex" v-for="i in filteredRows" :key="i.ID">
          <v-card class="mx-1 my-2" outlined>
            <v-card-title>{{ i.Name }}</v-card-title>
            <v-card-text>
              <div>
                <v-chip
                  class="ma-1"
                  color="green lighten-5"
                  text-color="black"
                  @click="openWorkStaffTimeDialog(i)"
                >
                  <v-avatar left
                    class="green darken-4"
                    style="color: white !important"
                  >
                    <v-icon small>mdi-alarm</v-icon>
                  </v-avatar>
                  <span>Registar horas</span>
                </v-chip>
                <router-link v-bind:to="'/staff/' + i.ID">
                  <v-chip
                    color="blue lighten-5"
                    text-color="black"
                  >
                    <v-avatar
                      left
                      class="blue darken-4"
                      style="color: white !important"
                    >
                      <v-icon small>mdi-forward</v-icon>
                    </v-avatar>
                    Ver
                  </v-chip>
                </router-link>
                <v-chip
                  @click="remove(i.ID)"
                  class="mx-1"
                  color="red lighten-5"
                  text-color="black"
                >
                  <v-avatar
                    left
                    class="red darken-4"
                    style="color: white !important"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-avatar>
                  Remover
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <StaffTimeDialog />
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import Create from './Create'
import StaffTimeDialog from './StaffTimeDialog'

export default {
  props: {
    idWork: Number
  },
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      filteredRows: [],
      hover: false,
    }
  },
  watch: {
    search: function (val) {
      this.filteredRows = this.rows.filter(function (a) {
        return a.Name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
    },
  },
  created () {
    bus.$on('refreshWorkStaffs', () => { this.getWorkStaffs() })
    this.getWorkStaffs()
  },
  beforeDestroy () {
    bus.$off('refreshWorkStaffs', null)
  },
  methods: {
    openWorkStaffTimeDialog(staff) {
      bus.$emit('openWorkStaffTimeDialog', {idWork: this.idWork, staff: staff})
    },
    getWorkStaffs () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/workstaff/' + this.idWork)
        //.get('http://localhost:13007/api/v1/workstaff/' + this.idWork)
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
          this.filteredRows = this.rows
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao carregar trabalhadores'})
        })
    },
    remove (id) {
      this.loading = true
      httpClient
        .delete('/mix/api/v1/workstaff/' + this.idWork + '/' + id)
        //.delete('http://localhost:13007/api/v1/workstaff/' + this.idWork + '/' + id)
        .then(() => {
          this.loading = false
          bus.$emit('refreshWorkStaffs')
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao remover trabalhador'})
        })
    }
  },
  components: {
    Create,
    StaffTimeDialog
  }
}
</script>